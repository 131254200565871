.filters {
  margin: 1rem 0 2em;

  @include breakpoint(large) {
      margin-right: 1.25rem;
      margin-left: 1.25rem;
      width: calc(100% - 2.5rem);
  }

  &.expanded  {

    .filter-toggle {

      a {
          background-color: $water-blue-dark;
          color: white;
          border-radius: 0;
          margin-bottom: 0;

          &:after {
            content: '-';
            color: white;
          }
      }

    }

    .filter-options {
      display: block;
      .cell {
        padding: 0;
      }
    }
  }
  
  .filter-title {
      font-weight: $global-weight-normal;;
      vertical-align: middle;
      display: inline-block;
      text-transform: uppercase;
      font-size: 0.85rem;
      letter-spacing: 0.1rem;
      margin-bottom: -4px;
      color: #54585A;
      display: none;

      @include breakpoint(xlarge) {
        display: block;
      }
  }

  .filter-toggle {

      @include breakpoint(xlarge) {
        display: none;
      }

    a {
      padding: .75rem 2.5rem .75rem 1.5rem;
      display: block;
      position: relative;
      font-weight: $global-weight-bold;
      font-size: 1rem;
      text-transform: uppercase;
      text-align: left;

      &:hover {
        &:after {
          color: white;
        }
      }

      &:after {
        top: 35%;
        right: 2rem;
        margin-top: -15px;
        position: absolute;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: 'Material Icons Round';
        font-weight: $global-weight-bold;
        color: $water-blue-dark;
        font-size: 1.5rem;
        content: '+';
        font-size: 18px;
      }

    }
  }

  .filter-options {
    margin: 0;
    background-color: $white;
    padding: 1rem;
    border-bottom: 1px solid $light-gray;
    position: relative;
    display: none;

    @include breakpoint(xlarge) {
      border: none;
      padding: 0;
      background-color: transparent;
      display: block;
    }

    @include breakpoint(large down) {
      .dropdown-pane {
        left: 1.5rem !important;
        right: 1.5rem !important;
        width: auto;
        margin-top: -2px;
      }
    }

    .fixed-col {
      @include breakpoint(xlarge) {
        display: inline-block;
        width: map-get($dropdown-sizes, large);
        margin-right: 1em;
      }
    }

  }
}


#views-exposed-form-news-page-2 {
  .filters {
    .form-item-field-post-date-value-min {
      width: 46%;
      margin-right: 20px;
    }
    .form-item-field-post-date-value-max {
      width: 46%;
    }
  }
}
