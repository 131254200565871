.toolbar {
	.toolbar-menu {
		background: #333 !important;
		.menu-item {
			height: 40px;
			a {
				color:white;
				margin-top: 12px;
			}
		}
	}
}

.user-logged-in {
	#page {
		margin-top: 20px;
	}
}