.hero-viewport {
  
    .hero-container {
        // Hero carousel
        background: $water-blue-dark;

         @include breakpoint(large){
            max-height: calc( 100vh - 400px );
            overflow: hidden;
         }

         @include breakpoint(overlylarge){
            max-width: 108rem; //1728px
            margin: 0 auto;
         }

        .orbit {
            .orbit-bullets {
                position: relative;
                text-align: left;
                z-index: 10;
                left: 20px;
    
                button {
                    cursor: pointer;
                    background: rgba(255, 255, 255, 0.5);
                    border: 1px solid white;
                    width: .6em;
                    height: .6em;

                    &.is-active {
                       background: $water-blue;
                    }
                }
            }

            .orbit-container {
                overflow: visible;
                outline: none;

                &:focus {
                    outline: 2px solid $water-blue;
                }

                @include breakpoint(large){
                    overflow: hidden;
                }

                .orbit-slide {
                    background: black;
                }
            }

            .orbit-slide-number {
                color: white;
                font-weight: bold;
            }
           
            .orbit-controls {
                position: absolute;
                transition: background-color 800ms;
                left: 0;
                top: 125px;
                width: 100%;
                text-align: left;
                z-index: 5;

                @include breakpoint(medium){
                    top: auto;
                    top: 150px;
                }

                @include breakpoint(large){
                    top: auto;
                    top: 276px;
                }

                @include breakpoint(xlarge){
                    top: 340px;
                }

                // Macbook Pro 13'
                @include breakpoint(xxlarge){
                    top: 400px;
                }

                @include breakpoint(xxxlarge){
                    top: 515px;
                }

                @include breakpoint(xxxxlarge){
                    top: 620px;
                }

                .orbit-navigation {
                    position: relative;
                    z-index: 2;
                    left: 11px;
                    top: 68px;
                    display: block;
                    width: 120px;
                    
                    @include breakpoint(large){
                        width: auto;
                        left: 20px;
                        top: 30px;
                        margin-left: auto;
                    }

                    .pause-icon, .play-icon, .back-icon, .forward-icon {
                        top: 0;
                        left: 0;
                        font-size: 1.5em !important;
                    }
                    
                    .orbit-next, .orbit-previous, .orbit-pause-play {
                        position: absolute;
                        top: 0;
                        left: auto;
                        display: inline-block;
                        width: 35px;
                        height: 35px;
                        color: white;
                        border: none;
                        cursor: pointer;
                        z-index: 10;
                        border-radius: 0;
                        background-color: transparent;
                        padding: 0;
                        
                        @include breakpoint(xlarge){
                            width:  40px;
                            height: 40px;
                        }
                    }

                    .orbit-previous { left: 0; }

                    .orbit-pause-play {
                        left: 36px;
                        top: -20px;

                        @include breakpoint(large){
                            left: 47px;
                        }
                    }

                    .orbit-pause-play {
                        .pause-icon { display: block; }
                        .play-icon { display: none; }

                        &.play {
                            .play-icon{
                                z-index: 1;
                                display: block;
                            } 
                            .pause-icon { display: none; }
                        }

                    }

                    .orbit-next { 
                        left: 74px;
                           
                        @include breakpoint(large){
                            left: 94px;
                        }
                    }

                }
            }
        }
        
        .hero-body {
            width: auto;
            overflow: hidden;
            display: flex;
            @include flex-order(1);
            position: relative;
            flex-direction: column;
            animation: fadeInAnimation ease 5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            @keyframes fadeInAnimation {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
                    
            img {
                width: 140%;
                margin-bottom: 0;
                position: relative;
                max-width: none;
                left: -32%;
                margin-top: 0;
                
                @include breakpoint(xlarge) {
                    min-width: 100%;
                    width: 100%;
                    left: auto;
                    margin-top: 0;
                }
            }
        }
        
        .hero-text-container {
            position: relative;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: end;
            width: 100%;
            //height: 233px; 
            font-size: 0.8rem;
            z-index: 3;
            background: transparent;
            @include flex-order(2);
            background: $water-blue-dark;
            color: white;

            @include breakpoint(large) {
                height: 100%;
            }

            @include breakpoint(xlarge) {
                
                bottom: 0; 
                font-size: 1.2rem;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                background: transparent;
                text-align: left;
                color: white;
             }

            &.dark {
                .hero-text-body {
                    color: white;
                    text-shadow: none;

                    @include breakpoint(xlarge){
                        h1 { color: darken($water-blue-dark, 5%) }
                        p { color: $water-blue-dark; }
                    }
                }
            }
   
            &.text-right {
               .hero-text-body {
                   float: right;
                   text-align: right;
                }
            }

             .hero-text-body {
                padding: 1.6em 1.2em;
                width: 100%;
            
                p { margin-bottom: 0;}

                .rg-hero {
                    margin-top: 25px;

                    .cell {
                        padding: 0;
                    }

                    p {
                        font-size: 16px;
                        padding: 11px 0 0 5px;
                    }

                    .button {
                        width: 100%;
                        margin-top: 41px;
                        text-align: center;
                        padding: .35em 2.2em .55em 2.2em;
                    }
                }

                @include breakpoint(large){
                    .rg-hero {
                        .button {
                            width: auto;
                            margin-top: 40px;
                            text-align: left;
                        }
                    }
                }
                
                @include breakpoint(xlarge){

                    width: 65%;
                    color: $white;
                    text-shadow: 2px 2px 3px rgba(0,0,0,.2);
                    
                    &.dark {
                        color: $black;
                    }

                    border: none;

                    &.right {
                        float: right;
                        text-align: right;
                    }

                    .rg-hero {
                    
                        p {
                            font-size: 1.1rem;
                            padding-top: 20px;
                        }

                        .rg-icon {
                            max-width: 60px;
                        }
                    }
                }

                @include breakpoint(large){
                    padding: 3em 4em;
                }

                @include breakpoint(xxlarge){
                    /* width: 605px; */
                    padding: 3em 1em;
                    
                    p { margin-bottom: 1em; }
                    
                }
            }

            h1 {
                &.hero-header{
                    font-size: 1.6rem;
                    margin-bottom: .5rem;
                    font-weight: $global-weight-bold;
                    
                    @include breakpoint(xlarge){
                        font-size: 2.3rem;
                        margin-bottom: 1rem;
                        line-height: 1;
                    
                    }
    
                    @include breakpoint(xxxlarge){
                        font-size: 4.2rem;
                    }
                }
            }
    
            h2 {
                &.hero-header{
                    font-size: 1.4rem;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    font-weight: $global-weight-bold;
    
                    @include breakpoint(xlarge){
                        font-size: 2.4rem;
                    }
    
                    @include breakpoint(xxxlarge){
                        font-size: 3.2rem;
                        line-height: 1.2;
                    }
                }  
            }

            p {
                @include breakpoint(xxlarge){
                    font-size: 1.2em;
                    line-height: 1.4;
                }
            }
        }

    }

    &.home {
        position: relative;

        .hero-container {
            min-height: none;
            max-height: none;
            height: 100%;
        
            @include breakpoint(xxlarge){
                max-height: 580px;
                min-height: 500px;
            }
        
            @include breakpoint(xxxlarge){
                max-height: 702px;
                min-height: 602px;
            }
        
            @include breakpoint(xxxxlarge){
                max-height: 770px; 
                min-height: 670px;
            }
        
        } 
    }

    &.inner-page {
        position: relative;

        .hero-container {
            //position: relative;
            min-height: none;
            max-height: none;
            top: 0;
            height: 100%;
            background: $water-blue-dark;

            &.downplay {
                .hero-text-body {
                    color: white;
                    h1 { font-size: 2.3em; }
                    p { font-size: 1.1em;}

                    @include breakpoint(large){
                        padding: 3em;
                    }

                    @include breakpoint(xlarge){
                        background: rgba(0,0,0, .6);
                        p { font-size: .85em;}
                    }
                }
            }
        
            a { text-decoration: none;}
        
            @include breakpoint(xlarge){
                max-height: 500px;
            }
        
            @include breakpoint(xxxlarge){
                max-height: 600px;
            }
        
            @include breakpoint(xxxxlarge){
                max-height: 700px; 
            }
        }

        &.xsmall { .hero-container { max-width: $grid-container-xsmall; margin: 0 auto; } }
        &.small { .hero-container { max-width: $grid-container-small; margin: 0 auto; } }
        &.medium { .hero-container { max-width: $grid-container-medium; margin: 0 auto; } }
        &.large { .hero-container { max-width: $grid-container-medium; margin: 0 auto; } }
        &.xlarge { .hero-container { max-width: $grid-container-xlarge; margin: 0 auto; } }
        &.wide { .hero-container { max-width: none; margin: 0; } }

    }

}
