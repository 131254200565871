.search-bar {
    width: 100%;
    /* border: $medium-gray 1px solid;
	background: white; */
	color: $water-blue;
	margin-bottom: 2rem;
	margin-top: 1.25rem;

	.input-group {
		@include xy-grid();
		margin-bottom: 0;

		.input-group-field {
			background: none;
			border: none;
			box-shadow: none;
			font-size: 1rem;
			transition: color 400ms;
			color: $medium-gray;
			z-index: 100;
			padding: 1rem;

			&:focus {
				color: $black;
			}

			@include breakpoint(large){
				font-size: 1.6rem;
			}
		}

		.input-group-button {
			& >  button, a, input {
				font-size: 1rem;
			
				@include breakpoint(large){
					font-size: inherit;
					padding: 1.2em 1rem;
					border: 2px solid transparent;
				}
				

			/* 	.material-icons-round {
					top: 14px;
					font-size: 22px !important;

					@include breakpoint(large){
						top: 6px;
					}
				} */
			}
		}
	}
}