.historical-fact {
    @include breakpoint(large down) {
      .page-feature {
        display: none;
      }
    }
  }
  
  // Timeline Component
  .historical-fact-timeline {
    padding: 4rem 0;
    background-color: $white;
    position: relative;
  
    .timeline-indicator {
      height: 1px;
      background-color: $maximum-green;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  
    .section-intro {
      margin-bottom: 6rem;
  
      @include breakpoint(large) {
      margin-bottom: 2.5rem;
    }
  
      h1 {
        @include breakpoint(large) {
        padding-right: 10rem;
      }
  
      }
    }
  
    .timeline {
      position: relative;
  
      .owl-nav {
        position: absolute;
        top: -80px;
        left: 0;
  
        @include breakpoint(large) {
          left: auto;
          right: 0;
          top: -92px;
        }
  
        button.owl-next,
        button.owl-prev {
          font-size: 1rem;
          width: 60px;
          height: 60px;
          line-height: 50px;
          font-weight: bold;
          text-align: center;
          color: $water-blue;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 0.5rem;
        }
  
        button.owl-prev {
          margin-right: 10px;
        }
  
      }
    }
  }
  
  
  // Featured Historical Fact Component
  .featured-historical-fact {
    padding: 6rem 0;
    background-color: $maximum-green;
    padding-bottom: 12rem !important;
    background-repeat: no-repeat;
    background-position: 95% 100%;
    background-size: 500px auto;
  
    .fact-wrap {
      background-color: $white;
    }
  
    .fact-content {
      text-align: center;
      padding: 3rem;
    }
  
    .fact-media {
      background-color: $black;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      min-height: 500px;
      @include breakpoint(xxlarge) {
        min-height: 600px;
      }
    }
  }
  
  .timeline-fact {
    .date {
      position: relative;
      padding-top: 30px;
      margin-bottom: 2rem;
      display: block;
      text-align: center;
  
      &:before {
        width: 1px;
        height: 20px;
        background-color: $maximum-green;
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
      }
    }
  
    .timeline-fact-media {
      margin-bottom: 1rem;
    }
  
    .timeline-fact-content {
      text-align: center;
    }
  }

  .historical-event {
    &.widget {
      .timeline-item {
        opacity: .9;
        background: #f3f8f9 url(../img/timeline-mark.svg) top left no-repeat;
        position: absolute;
        bottom: 0;
        background-size: 16px 16px;
        background-position: 73px 15px;
        margin-bottom: 0;
        align-items: flex-start;

        .event {
          font-size: .9rem;
        }
      }
    }

  }
  