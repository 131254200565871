.widget {
    //margin-bottom: 3.5rem;

    .media-card {
        .image {
            max-height: none;

            @include breakpoint(large){
                width: 80px;
                height: 80px;

                img { width: auto; }
            }
        }

        .card-content {
            font-size: .9rem;
        }
    }

    h5 {
        margin-bottom: 1em;
    }

    .image-gallery{
        margin-top: 1rem;
        
        .owl-carousel {
            .owl-nav {
                position: relative;
                left: 0;
                top: 10px;
                width: 100px;

                button.owl-next,
                button.owl-prev {
                    font-size: 1rem;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    border-radius: 50%;
                    display: inline-block;
                    margin: 0 .5rem 0 0;

                    &.disabled {
                        opacity: .4;
                        cursor: disabled;
                    }
                }
            }
        }
    }

    .list-linked {
        font-size: .95em;
    }
}

.column-support {
    .widget {
        margin-bottom: 3.5rem;
    }
}