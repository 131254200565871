.form_style_1 {
    padding: 1rem 4rem;

    label {
        color: white;
        text-align: left;
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 3px;
    }

    input {
        height: 4em;
    }
}

.form_style_2 {
    label {
        font-weight: bold;
        font-size: 1.125rem;
        margin-bottom: 3px;
    }

    input, select {
        height: 4em;
    }

    textarea {
        min-height: 200px;
    }
}