.pagination-nav {
    text-align: center;
    margin: auto;
    
    .pagination {
        @include xy-cell(auto);
        margin: 0 auto;
        
        a { 
            padding: 0.25rem 0.5rem;
        }

        li {
            display: inline-block;
        }
        
        .disabled {
            padding: 0;

            @include breakpoint(large){
                padding-left: .5em;
                padding-right: .5em;
            }

            a {
                color: $dark-gray;
            }
        }

        .current {
            background-color: $water-blue-dark;
            padding: .25rem 0;

            a {
                color: #fcfcfc;
                padding: 0.25rem 0.9375rem;
                
                &:hover {
                    color: #fcfcfc;
                    background-color: transparent;
                }
            }
        }

        li {
            font-size: 1em;
        }
    }

}