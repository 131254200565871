/* === IMAGE RULES === */

figure {
    //display: table;
    display: flex !important;
    flex-flow: column !important;
    //justify-content: flex-start !important;
      
    &.align-center, &.align-left, &.align-right {
        padding: 0;
    }
    
    

    img {  
        margin-bottom: 1em;
        max-width: 100%;
       
        &.align-right {
            margin-left: auto !important;
        }
        
         &.align-center + figcaption {
            @extend .img-center;
            margin-top: 0 !important;
         }

         &.align-left + figcaption {
            @extend .img-left;
            margin-top: 0 !important;
            
         }

         &.align-right + figcaption {
            @extend .img-right;
            margin-top: 0 !important;
            margin-left: auto !important;
         }
    }
}

img {
    max-width: 100%;
}

.img-full {
    margin-bottom: 2em;
    width: 100%;

    display: block;

    img {
        width: 100%;
    }
}

.grid-container {

    .img-full {
        .image {
            max-height: 500px;
            overflow: hidden;
        }
    }

    &.large {
        .img-full {
            .image {
                max-height: 650px;
                overflow: hidden;
            }
        }
    }
}

.img-75 {
    margin-bottom: 2em;

    img {
        width: 100%;
    }

    @include breakpoint(large) {
        width: 75%;
    }

    figcaption {
        align-self: flex-start;
    }
}


.img-50 {
    margin-bottom: 2em;

    img {
        width: 100%;
    }

    @include breakpoint(large) {
        width: 50%;
    }

    figcaption {
        align-self: flex-start;
    }
}

.img-25 {
    margin-bottom: 2em;

    img {
        width: 100%;
    }

    figcaption {
        display: none;
    }

    width: 100%;

    @include breakpoint(large) {
        width: 25%;

        figcaption {
            display: block;
        }
    }

    figcaption {
        align-self: flex-start;
    }
}

/* Centered Images */

.img-center {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;

    figcaption {
        align-self: flex-start;
    }
}

.img-center-75 {
    @extend .img-75;
    @extend .img-center;
}

.img-center-50 {
    @extend .img-50;
    @extend .img-center;
}

.img-center-25 {
    @extend .img-25;
    @extend .img-center;
}


/* Left Aligned Images */

.img-left {
    float: left;
    margin-right: 2em;

    figcaption {
        align-self: flex-start;
    }
}

.img-left-75 {
    @extend .img-75;
    @extend .img-left;
}

.img-left-50 {
    @extend .img-50;
    @extend .img-left;

    @include breakpoint(medium down) {
        margin: 0;
    }
}

.img-left-25 {
    @extend .img-25;
    @extend .img-left;
}


/* Right Aligned Images */

.img-right {
    float: right;
    margin-left: 2em;

    figcaption {
        align-self: flex-start;
    }
}

.img-right-75 {
    @extend .img-75;
    @extend .img-right;
}

.img-right-50 {
    @extend .img-50;
    @extend .img-right;

    @include breakpoint(medium down) {
        margin: 0;
    }
}

.img-right-25 {
    @extend .img-25;
    @extend .img-right;

    figcaption {
        padding-left: 1.2rem;
    }
}

.break-padding-left-right {
    @include breakpoint(medium down) {
        margin: 0 -2em 1em -2em !important;
        width: calc(100% + 4em);
    }
}


/*==== Captions ===*/


// _images
figcaption {
    //display: table-caption;
    caption-side: bottom;
    font-size: .9em;
    font-weight: bold;
    position: relative;
    padding-left: 12px;

    /* &:before {
        position: relative;
        left: -15px;
        top: 0;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Material Icons Round";
        width: 0;
        content: "expand_less";
    } */
}


figcaption,
.caption {
    margin-top: 15px !important;

    .caption-title {
        font-weight: $global-weight-bold;
        color: $dark-gray;
    }

    .caption-desc {
        color: $dark-gray;
    }

}


.clearfloat {
    @include clearfix;
}