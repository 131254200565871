
.grid-container, .landing-intro {
    //position: relative;
    max-width: 832px; //53em
    clear: both;
    width: 100%;
    margin: 0 auto;
    
    @include breakpoint(large){
        max-width: 80em; //80em
    }

    &.xsmall { max-width: $grid-container-xsmall; }
    &.small { max-width: $grid-container-small; }
    &.medium { max-width: $grid-container-medium; }
    &.xlarge { max-width: $grid-container-xlarge; }

    // All of this needs to be incorporated into a smarter system FIX
    &.large {
        max-width: $grid-container-large; //70em
        
        &.force-full {
            @include breakpoint(xlarge){
                padding: 0;
                margin: 0;
                max-width: none;
            }
        }
        
        @include breakpoint(xlarge){
            &.force-full {
                margin: 0 auto;
                max-width: 1280px; //80em
            }

            max-width: 1280px; //80em
        }

        @include breakpoint(xxlarge){
            max-width: 1360px; //85em
            padding: 0 3rem;

            &.force-full {
               max-width: 1360px; //85em
                padding: 0;
            }

        }
        
        @include breakpoint(xxxlarge){
            max-width: 1440px; //90em
            

            &.force-full {
                max-width: 1440px; //90em
                padding: 0;
            }
        }
    }
}

.page-utilities {
    padding: .6rem 0 .5rem;
    border-bottom: 1px solid $medium-gray;
    border-top: 1px solid $medium-gray;
   
    @include show-for(large);

    .material-icons-round {
        font-size: 16px !important;
    }

    .breadcrumbs {
        margin-bottom: 0;
          
        a:hover {
            text-decoration: none;
        }
        
        li {
            color: $black;
            font-weight: $global-weight-bold;
            font-size: .8rem;
            text-transform: none;
            margin: 0 .1em;

            @include breakpoint(xlarge){
                font-size: .9rem;

                &:last-child {
                    width: 500px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
    
                &:not(:last-child){
                    &::after {
                        margin: 0 .3em;
    
                        @include breakpoint(large){ 
                            margin: 0 .75em;
                        }
                    }
                }
            }

            &:first-child {
                position: relative;
                top: -2px;
                
                &::before {
                    content: '\e88a';
                    font-family: 'Material Icons Round';
                    top: 2px;
                    position: relative;
                   
                }
            }

            a {
                color: $water-blue-dark;
                text-decoration: none;
                font-weight: normal;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.page-content {
    position: relative;

    .content {
        overflow: hidden;
    }
    
    @include breakpoint(xlarge){
        padding: 3rem 0;
    }

    ul, ol {
        margin-bottom: 1em;
    }
}

.sidebar {
    @include breakpoint(large){
        padding-left: 5%;
    }

    &-container {
        padding: 10px 15px;
    }
}

.page-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.image-footer {
    margin-top: -40px;

    @include breakpoint(large){
        max-width: 105em;
        margin: -290px auto 0 auto;
    }
}
