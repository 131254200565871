.callout {

    border: none;
    padding: 2rem;
    margin-bottom: 2rem;

   /*  p, a { display: inline-block;} */
    
    p:last-of-type { margin-bottom: 0;}

    & > *:first-child, .button {
        margin-top: 0;
    }

    &.status-info {
        background-color: transparent;
    }

    &.status-primary {
        background-color: lighten($power-yellow, 45%);
    }

    &.status-informational {
        background-color: transparent;
    }

    &.status-secondary {
        background-color: $light-gray;
    }

    &.status-warning {
        background-color: lighten($warning-color, 50%);
    }

    &.status-alert {
        background: lighten($alert-color, 40%);
    }

}
