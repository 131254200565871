
.water-blue-text { color: $water-blue }
.water-blue-dark-text { color: $water-blue-dark; }
.sewer-green-text{ color: $sewer-green }
.power-yellow-text{ color: $power-yellow }
.middle-blue-purple-text { color: $middle-blue-purple }
.firebrick-text{ color: $firebrick }
.red-pigment-text { color: $red-pigment }
.flame-text { color: $flame }
.dark-tangerine-text { color: $dark-tangerine }
.mellow-yellow-text { color: $mellow-yellow }
.maximum-green-text { color: $maximum-green }

.white-text {color: $white }
.gray-text {color: $black; }

.subhead {
    color: $water-blue;
    margin: 0 0 1.5rem 0;
}

h1, h2, h3, h4, h5, p {
    text-rendering: auto;
}

.heading {
    width: 100%;
    text-align: center;
    padding-top: 2rem;

    h1, h2 {
        color: $black;
        padding: 0;
        margin: 0 0 1.5rem 0;
        display: inline-block;
        text-align: center;

        &:after {
            content: '';
            height: 2px;
            background: $middle-blue-purple;
            width: 70%;
            display: block;
            margin: 10px auto;
        }
    }

    h5 {
        text-transform: uppercase;
        color: $water-blue;
    }
}
