.horizontal-tabs {
    .tabs {
        text-align: center;
        border: none;
        background: transparent;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        font-weight: $global-weight-bold;
    }

    .tabs-centered {

        .tabs-title {
            display: inline-block;
            float: none;

            > a {
                font-size: 1em;
                padding: .75rem;
            }
        }
    }

    .tabs-panel {
        padding: 2em 0 0 0;
        display: block;

        .cell {
            margin: 0 0 0 1em;
            
           .content {
                margin: 0;
                background: $light-gray;
                width: 100%;
                height: 100%;
                padding: 2em;
            } 

            a {
                color: $water-blue-dark;
            }
           
            h5 {
                margin-bottom: 15px;
            }
        }
    }

    .tabs-title {
        flex: 1 1 0px;
        width: auto;

        &.is-active {
            &> a {
                &.button {
                    &:hover {
                        background: $water-blue;
                        color: white;
                    }
                }
            }
        }
        
        &> a {
            font-size: 1rem;
            padding: .8rem 0;
            text-transform: none;
            text-decoration: none;
            font-weight: $global-weight-bold;
            color: $water-blue-dark;
            line-height: 1.3;

            &:hover {
                color: $water-blue;
                background: rgba($water-blue, 0.1);
            }

            &:focus {
                background-color: $light-gray;

                &:hover {
                    background-color: $light-gray;
                }
            }

            &[aria-selected='true'] {
                border-bottom: 2px solid $water-blue;
                background: transparent;
            }
        }
    }

    .tabs-content {
        border: none;
        background: transparent;
        *zoom: 1;
        display: block;
        padding: 1rem 1rem 0 1rem;
        text-align: left;

        &::before, &::after {
            content: "";
            display: table; 
        }

        &::after {
            clear: both; 
        }

        &> .tabs-panel {
            display: none;
            float: left;
            padding: 0.9375rem 0;

            &.is-active {
                animation: fadeIn 1s;
                opacity: 1;
                display: block;
            }
        }

        .inner {
            @include xy-grid;
        }

        .content {
            @include breakpoint(xlarge) {
                @include xy-cell(auto);
            }
        }

        .media {

            @include breakpoint(xlarge) {
                @include xy-cell(5);
            }

        }

        .menu a {
            padding: .4em 0;

            @include breakpoint(xlarge) {
               padding-top: .5em;
               padding-bottom: .5em;
            }
        }

    }
    
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}