

.left-off-canvas-toggle {
    position: absolute;
    top: 0;
    padding-left: 12px;
    margin-left: 0;
    margin: 0;
    border: 0;
    z-index: 1000;
    left: 0;
    background: #eeeeee;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    // close menu hidden by default
    .close-menu { display: none; }

    &:focus {
        color: black;
    }


    &.is-open {
        .open-menu {
            display: none;
        }

        .close-menu {
            display: block;
        }
    }
    
} 

.layout-two-column {
    .nav-sidebar {
        border-radius: 20px;
        background-color: $light-gray;
        padding: 2em 2em 2em 1.5em;

        .menu {
            word-wrap: break-word;
            padding: 0;
        }

        .menu a {
            line-height: 1.4;
        }
    }
}

.nav-sidebar {
    font-size: .9em;

   .section-title {
        a {
            font-weight: $global-weight-bold;
            font-size: 1.2rem;
        }
    }

    .menu {
        padding: 0 1rem;
        line-height: 1.3em;

        a {
            padding: .6em 0;
            color: $water-blue-dark !important;
        }

        .subsection-title, .section-title, .subsubsection-title {
            font-weight: $global-weight-bold;
        }

        .section-title {
            font-size: 1.2em;
        }

        .subsection-title {
            font-size: 1.1rem;
            position: static;
            padding: 0 0 .4em 0;

            p {
                font-size: 1.1rem;
                position: relative;
                left: 22px;
                font-weight: bold;
                margin-bottom: 0;
            }

            .material-icons-round {
                position: absolute !important;
                top: 2px !important;
            }
        }

        @include breakpoint(large){
            width: 100%;
            position: relative;
            font-size: 1em;

            a {
                transition: color 800ms;
            
                &:hover {
                    color: darken($water-blue, 0);
                    text-decoration: underline;
                }
            }

            .nested {
                padding-left: 22px;
                margin-left: 0;
                
                &.l1 {

                    li { 
                        border-top: 1px solid darken($medium-gray, 10%);
                    }
                    
                }
                
            }
        }
    }

    &.in-this-section {
        .menu {
            padding: 0;
        }
    }
    
    .nested .is-active {
        font-weight: $global-weight-bold;
        padding: 0;

        a {
            background-color: transparent;
            font-weight: $global-weight-bold;
        }
    }

    &.mobile-menu-accordion {
        > .menu {
            margin: 1em;

            a {
                margin: 0;
                padding: .5em 0;
                font-size: 1em;
            }
        }

        .section-title {
            font-size: .9em;
        }
    }
}

.page-menu-header {
    position: relative;
    font-weight: $global-weight-bold;
    margin-bottom: 10px;

    .page-menu-trigger {
        width: 100%;
        margin: 0;
        position: relative;
        text-align: left;
        border-radius: 0;
        background: $light-gray;
        padding: 1em;
        font-weight: $global-weight-bold;
        color: $black;
    
        .material-icons, .material-icons-round {
            margin-top: 0;
            margin-right: 10px;
            float: right;
            align-self: flex-end;
        }

    }
}

.multilevel-offcanvas {

    &.off-canvas {
        box-shadow: none;
        z-index: 4;
        background: transparent;
        
        &.is-closed {
            visibility: visible;
        }
    }

    .menu {
        margin-bottom: 1rem;
        width: 100%;
        right: auto;
        
        a {
            padding-top: 7px;
            padding-bottom: 7px;
            transition: color 0.2s ease-in;
        }
    }
}

.off-canvas-content {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.64, 0.57, 0.78, 1.04);
    transition-duration: .5s;

    .js-off-canvas-overlay {
        display: none;
        visibility: hidden;
    }

    &.is-open-left {
        width: calc( 100% - 425px );
        transform: translateX(325px) !important;
        max-width: none;
        margin: 0;
        
        @include breakpoint(xxxxlarge){
            //overriding default transition
            transform: translateX(0) !important;
            width: 100% !important;
        }
    }
}