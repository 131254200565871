.search-container {
   background: transparent;
   box-shadow: none;
   overflow: visible;
   text-align: center;
   @include xy-grid;
   text-align: center;
   opacity: 0;
   transition: all 500ms ease;

   &.is-open {
       opacity: 1;
       &.off-canvas {
            box-shadow: none;
            z-index: 10000;
        }
   }

   .form-text {
       border: 1px solid white;
   }

   .form-item-search {
      width: 80%;
      float: left;
      label {
        display: none;
      }
   }

   #edit-search, .edit-search--2, #edit-search--3 {
      height: 4rem;
   }

   #edit-submit-search--2,  #edit-submit-search--3, #edit-submit-search--4 {
       max-height: 4em;
   }

   #edit-actions, #edit-actions--2, #edit-actions--3 {
      float: left;
      width: 20%;

      .button {
        font-size: inherit;
        padding: 1.2em 1rem;
        border: 2px solid transparent;
        background-color: white;
        color: #002D74;
        border-radius: 0 0 0 0
      }
   }

   .footer-links {
      clear: both;
   }

   .input-group-button a, .input-group-button button {
       padding-top: 1rem;
       padding-bottom: 1rem;
    }   

   @include breakpoint(medium){
        height: auto;
        width: 100%;
   }

    .global-search {
        z-index: 5000;
        padding: .5rem 1.5rem 3rem 1.5rem;
        color: white;
        max-width: 900px;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        
        background: $water-blue;
        position: relative;
        min-height: 100vh;

        @include breakpoint(large){
            padding: .5rem 3rem 3rem 3rem;
            min-height: auto;
        }

        &:before {
            background: $water-blue-dark;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            content: "";
        }

        .footer-links a, .close-modal {
            color: white;
        }

        .close-modal {
            position: absolute;
            top: 8px;
            right: 8px;
        }

        .footer-links {
            text-align: left;
            a {
                display: block;
                margin: 0 0 .7em;
            }
        }

        .button {
            max-width: none;
            margin: 0 auto;
            height: 4em;
            line-height: 1.4;
            position: relative;

            &:focus {
                &:before {
                    color: white;
                }
            }
            
            @include breakpoint(large) {
                max-width: 450px;
                max-height: none;
            }
        }
    }
}

.search-page {
    h2 {
        font-size: 1.375rem;
        line-height: 1.3;
        margin-bottom: 0.5rem;
    }

    .search-url { color: #002D74; }

    .views-row {
        padding-right: 1em;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

