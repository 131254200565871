.accordion {
    background: transparent;
    margin: 1em 0;
}

.accordion-item {
    margin-bottom: 20px;
    text-align: left;

    .accordion-content {
        border-bottom: 1px solid #f1f1f1;
        margin-bottom: 2em;
        background: white;

        @include breakpoint(large){
            padding: 2rem 3rem;
        }
    }

    .accordion-title {
        background: #f1f1f1;
        color: $black;
        font-size: 1em;
        padding: 1.2rem 3rem 1.2rem 1rem;
        font-weight: $global-weight-normal;
        line-height: 1.1;

        h4 {
            margin-bottom: 0;
            font-size: 1.3em;
        }

        &:before {
            margin-top: -10px;
            font-size: 1.5em;
        }
    }
    
    &.is-active {
        .accordion-title {
           background: white;
           font-weight: 700;
           font-size: 1.1rem;
            text-decoration: none;
        }
    }
}