a.morelink {
	text-decoration:none;
	outline: none;
}

.morecontent span {
	display: none;

}

.button {
    text-decoration: none;
    text-transform: uppercase;
    margin: 1em 0;
    padding: .55em 2.2em;
	vertical-align: middle;
	position: relative;
    z-index: 1;
    backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 2px;
    word-break: keep-all;
    line-height: 2;
    border-radius: 50px;
    text-align: left;
    font-size: .9em;
    font-weight: $global-weight-bold;
    text-shadow: none;
    background: white;
    border: 2px solid $water-blue-dark;
    color: $water-blue-dark;

    @include breakpoint(large){
        font-size: 0.9rem;
    }

    // Default hover state
    &:hover {
        background-color: $water-blue;
        color: white;
        border: 2px solid $water-blue;
    }

    &:focus, &:focus:hover {
        background-color: $water-blue-dark !important;
        color: white !important;
        border: 2px solid $water-blue !important;
    }

    &.tiny {
        min-width: inherit;
        border-width: 1px !important;
    }

    &.expanded {
        display: block;
        text-align: center;
    }

    .posted-in {
        .button {
           margin-right: .25rem;
          }
     }
    
    > span {
        vertical-align: middle;
    }

    // Primary button. Defaults dark blue text color and border.
    &.primary {
        border: 2px solid $water-blue-dark;
        color: $water-blue-dark;
        background-color: transparent;

        &:hover {
            border: 2px solid transparent;
            background-color: rgba($water-blue, .85);
        }
        
    }

    // Secondary button. Defaults dark blue text color and border.
    &.secondary {
        color: $water-blue-dark;
        background-color: transparent;
        border: 2px solid transparent !important;
        position: relative;
        display: inline-block;

        span {
            display: inline-block;
            position: relative;
            will-change: transform, opacity;

            .material-icons-round {
                top: 3px !important;
            }
            
            &::before {
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 2px;
                transform: scaleY(1);
                transition: transform .3s, opacity .4s;
                content: '';
            }
        }

        &:hover {
            border: 2px solid $water-blue !important;
            span {
                &::before {
                    transform: scaleX(0);
                    opacity: 0;
                }
            }
        }

        &.light {
            span {
                &::before {
                    background: white;
                }
            }
        }

       &.dark {
            span {
                &::before {
                    background: $water-blue-dark;
                } 
            }
        }
    }

    &.tiny {
        border-width: 2px !important;
    }

    &.bare {
        color: $water-blue-dark;
        background-color: transparent;
        border-radius: none;
        border: 2px solid transparent;
        position: relative;
        display: inline-block;

        &:before {
            left: 20px !important;
            font-size: 1.3em !important;
        }

        &.light {
            color: white;
        }

        &.dark {
            color: $water-blue-dark;
        }

        &:hover {
            border: 2px solid transparent !important;
            background-color: rgba($water-blue, .85) !important;
            color: white !important;

            &:before {
                color: white !important;
            }
        }
    }

    &.success-button {
        color: $success-color;
        background: transparent;
        border: 2px solid $success-color;

        &:hover {
            color: white;
            border: 2px solid transparent;
            background: $success-color;
        }
    }

    &.warning-button {
        color: $warning-color;
        background: transparent;
        border: 2px solid $warning-color;

        &:hover {
            color: white;
            border: 2px solid transparent;
            background: $warning-color;
        }
    }

    &.alert-button {
        color: $alert-color;
        background: transparent;
        border: 2px solid $alert-color;

        &:hover {
            color: black;
            border: 2px solid transparent;
            background: $alert-color;
        }
    }


    // Button that is a filter button
    &.filter-button {
        border-bottom: 1px solid $light-gray;
        background: $white;
        width: 100%;
        border-radius: 0;
        font-weight: $global-weight-bold;
        text-align: left;
        margin: 0;
        color: $water-blue-dark;

        &::after {
            display: none;
        }

        &:hover {
            color: white;
            background: $water-blue;
        }
    }

    &.primary, &.secondary, &.success-button, &.warning-button, &.alert-button, &.filter-button {
        transition: all 0.3s;
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    }

    // White text, white border, no background
    &.light {
        color: white;
        border: 2px solid white;
    }

    // White text, white border, no background
    &.dark {
        color: $water-blue-dark;
        background: transparent;
    }
}

.filter-options {

    .filter-title {
        margin-bottom: 2px !important;
    }

    .form-select, .bef-datepicker {
        border: none;
        border-bottom: 1px solid $light-gray;
        background: white;
        width: 100%;
        border-radius: 0;
        font-weight: $global-weight-bold;
        text-align: left;
        margin: 0;
        color: $water-blue-dark;
        transition: all 0.3s;
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        text-decoration: none;
        text-transform: uppercase;
        // BF Changing this to align the Filter By: label
        margin: 0;
        padding: .85em 2.2em;
       //margin: 0 0 1em 0;
        //padding: .55em 2.2em;
        
        vertical-align: middle;
        position: relative;
        z-index: 1;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: 2px;
        word-break: keep-all;
        line-height: 2;
        text-align: left;
        font-size: .9em;
        font-weight: $global-weight-bold;
        text-shadow: none;
        height: auto;

        option {
            min-height: .9em;
            padding: 0;
        }

        &:hover {
            color: white;
            background: $water-blue;
        }
    }
}

// Button at lower right of the screen that shows on longer pages on scroll
.back-to-top-button {
    display: inline-block;
    background-color: transparent;
    border: 2px solid $power-yellow;
    width: 50px;
    height: 50px;
    text-align: center;
    position: fixed;
    bottom: 30px;
    right: 30px;
    will-change: background-color, border;
    transition: background-color .3s, border .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    display: none;

    @include breakpoint(large){
        display: block;
    }

    &::after {
        content: "expand_less";
        font-family: 'Material Icons Round';
        font-weight: $global-weight-normal;
        font-style: normal;
        font-size: 2em;
        line-height: 50px;
        color: $power-yellow;
    }

    &:hover {
        cursor: pointer;
        background-color: $power-yellow;
        text-decoration: none;
        &::after {
            color: white;
        }
    }

    &:active {
        background-color: #555;
    }

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

p.subheader, p.lead {
    a {
        &[href$=".pdf"] { 
            &::after {
                padding-right: 0;
            }
        }
    }
}

// Thumbnail treatments with links. Entire card is a link
a:not(.card-content), a:not(.media-card) {
    &[href$=".pdf"], &[href$=".com"], &[target="_blank"] {
        &::after {
            font-family: 'Material Icons Round';
            font-weight: normal;
            font-style: normal;
            font-size: 16px;
            display: inline-block;
            line-height: 1;
            text-transform: none;
            letter-spacing: normal;
            word-wrap: normal;
            white-space: nowrap;
            direction: ltr;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            font-feature-settings: 'liga';
            content: '\e89e';
            position: relative;
            top: 2px;
            left: 2px;
            //padding-right: 14px;
        }
    }

    .image {
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: cover;
            border: 0;
            transition: transform .35s ease-in-out;
            transform: scale(1);
        }
    }
    
    &:hover, &:focus {
        .image img {
            transform: scale(1.1);
        }
    }
}

.nav-social {
    a {
        &[target="_blank"] {
            &::after {
             display: none;
            }
        }
    }
}


a.media-card, .simple-button-list a, .list-linked a {
    &[href$=".pdf"], &[href$=".com"] { 
        &::after {
            display: none;
        }
    }
}

// Thumbnail treatments with links. Image only is a link
.image {
    a {
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: cover;
            border: 0;
            transition: transform .35s ease-in-out;
            transform: scale(1);
        }

        &:hover, &:focus {
            img {
                transform: scale(1.1);
            }
        }
    }
    
   
}

// Filtering Dropdown button
.dropdown-pane {
    box-shadow: 0 6px 12px rgba($black,0.1);
    padding: .75rem 0 1rem 0;
    border: none;
    background: $white !important;
    min-height: auto !important;
  
    > ul {
        display: block;
        position: relative !important;
    
        > li {
            display: block;
            width: 100%;

            > a {
                border-bottom: none;
                font-size: .9rem;
                line-height: 1.3;
                padding: .4em 1.5em;
                color: $water-blue-dark;
        
                &:hover, &:focus {
                    background-color: transparent;
                    text-decoration: underline;
                    color: $water-blue;
                }
            }
        }
    }

    .menu {
        right: 0 !important;
    }
}

#views-exposed-form-news-page-1 {
    .form-col-4 {
        @include breakpoint(large){
            width: 23%; 
            float: left;
            margin-right: 10px;
        }
    }
    
    .form-col-2 {
        @include breakpoint(large){
            width: 51%; 
            float: left;
            position: relative;
        }
    }

    .form-item-field-post-date-value-min {
        width: 100%;

        @include breakpoint(large){
            width: calc(50% - 1.25rem);
            //margin-left: 1.25rem;
            margin-right: 1.25rem;
        }
    }

    .form-item-field-post-date-value-max {
        width: 100%;

        @include breakpoint(large){
            width: calc(50% - 1.25rem);
            margin-left: 1.25rem;
            //margin-right: 1.25rem;
        }
    }
}
  