.image-gallery {
   
    .count {
        font-weight: 600;
        width: 80px;
        line-height: 1;
        color: $water-blue-dark;

        .counter {
            font-size: 1.1rem;

            .current {
                font-weight: $global-weight-bold;
            }

        }

    }

}

.image-gallery {
    margin-top: 2em;
    margin-bottom: 2em;

    .owl-carousel {
        .owl-nav {
            position: relative;
            left: 1em;
            top: 10px;
            width: 87px;
            margin-left: 24px;

            @include breakpoint(large) {
                left: 0;
            }

            button.owl-next {
                font-size: 1rem;
                width: 35px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                border-radius: 50%;
                display: inline-block;
                margin: 0 .5rem 0 0;

                &.disabled {
                    opacity: .4;
                    cursor: disabled;
                }
            }
            button.owl-prev {
                font-size: 1rem;
                width: 35px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                border-radius: 50%;
                display: inline-block;
                margin: 0 .5rem 0 0;
                position: absolute;
                left: -26px;
                &.disabled {
                    opacity: .4;
                    cursor: disabled;
                }
            }
            
        }
    }

    .item-info {
        padding: .8em !important;
        background: #f1f1f1;

        @include breakpoint(large) {
            padding: 1em 0 1em 1.5em !important;
        }
    }

    .item-caption {
        padding-left: 80px;
        padding-right: 1rem;

        p {
            font-size: .95rem;
        }

        >*:last-child {
            margin-bottom: 0;
        }
    }

}