 /**
    * (optional) define here the style definitions which should be applied on the slider container
    * e.g. width including further controls like arrows etc.
*/

.video_slider {

    width: 100%;
    position: relative;

    .frame {
        /**
        * (optional) wrapper width, specifies width of the slider frame.
        */
        width: 100%;
        
        position: relative;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        white-space: nowrap;

        .slides {
            display: inline-block;
            margin-left: 0;
        }
    
        .js_slide {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 200px;
            //opacity: 50%;
            transition: opacity 900ms;
            white-space: normal;

            /**
            * (optional) if the content inside the slide element has a defined size.
            */
            @include breakpoint(large) { 
                width: 600px;
                height: 336px;
                margin: 0 10px 0;
            }
           
            iframe {
                height: 100%;
                width: 100%;
                border: none;

                .html5-video-player {
                    background: none !important;
                }
            }
        }
    }

    .prev, .next {
        position: relative;
        bottom: 0;
        display: inline-block;
        cursor: pointer;
    }

    .next {
        left: 15px;
    }

    .prev {
        left: 10px;
    }

    .next svg, .prev svg {
        width: 43px;
    }

    .view-more {
        margin-top: 0;
        top: -8px;
        left: 30px;
    }

}
