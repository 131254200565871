.responsive-tabs-accordion {
    max-width: 75rem;
    margin: 0 auto;

    &.tabs {
        display: flex;
        border: none;
        background: transparent;
    
        .tabs-title {
            flex: 1 1 0;
            width: auto;
            text-align: center;
            margin: .75em .75em 0 .75em;
            display: flex;
          
            &>:first-child {
                margin-left: 0;
            }

            &>:last-child {
                margin-right: 0;
            }
    
            &.is-active {
                &> a {
                    color: $water-blue-dark;
                    background: transparent;

                    &:after {
                        transform: scale(1);
                        background: $water-blue;
                    }
                }
            }
            
            &> a {
                font-size: 1em;
                padding: .8rem;
                text-transform: none;
                text-decoration: none;
                font-weight: $global-weight-normal;
                color: $black;
                line-height: 1.4;
                position: relative;
                color: darken($water-blue-dark, 15%);
                
                &:after {
                    content: '';
                    height: 4px;
                    position: absolute;
                    top: 96%;
                    left: 0;
                    width: 100%;
                    background-color: $water-blue-dark;
                    display: block;
                    transform-origin: center;
                    transform: scaleX(0);
                    transition: transform 200ms ease-in-out;
                }
    
                &:hover {
                    background: transparent;
                    
                    &:after {
                        transform: scale(1);
                    }
                }
            }
        }
    }

    &.accordion {

        display: block;

        .accordion-item {
            .copy {
               
                display: none;
    
                @include breakpoint(large){
                    display: block;
                }
            }
        }

        .accordion-content {
            padding: 0;
        
            @include breakpoint(xlarge) {
                padding: 1em;
            }

            .cell {
                font-size: .9em;
                padding: 0;

                 &:last-child {
                     @include breakpoint(large){
                        padding-bottom: 1em;
                     }
                }

                @include breakpoint(xlarge){
                    padding: 1em 0;
                }

                &:first-of-type {
                    .content {
                        max-height: 250px;
                        overflow: hidden;
                        padding: 0;
                    }
                }

                .content {
                    background: $white;
                    padding: 1em 1.5em;
                    height: auto !important;
                    text-align: left;

                    .menu a {
                        padding: .6em 0;
                        color: $water-blue-dark;
                    }
                }
            }
        }

        .accordion-content {
            padding: 0;
        }
        
    }
}
  
  .tabs-content > .tabs-panel.is-active {
      animation: fadeIn 1s;
      opacity: 1;
      display: block;
  }

.tabs-content {
    color: inherit;
    transition: all 0.5s ease;
    *zoom: 1;
    display: block;
    padding: 0 1rem;
    text-align: left;
    border: none;

    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
      }

    

    .tabs-panel {
        display: none;
        min-height: 300px;
        padding: 0 2em 2em 2em;

        .cell {

            &:first-child {
                .content {
                    background: transparent;
                    padding: 0;
                }
            }

           .content {
                margin: 0;
                width: 100%;
                height: 100% !important;
                padding: 2em;

                .copy {
                    padding: 2.2rem;
                    background: $water-blue-dark;
                }

                .category, .copy {
                    color: white;
                }

                .menu a {
                    color: $water-blue-dark;
                    padding: .8em 0;
                    border-bottom: 1px solid $medium-gray;

                    @include breakpoint(xlarge){
                        font-size: .9em;
                    }
                }
            }
           
            h5 {
                margin-bottom: 15px;
            }
        }
    }
}

.home .tabs-content {
    background: transparent;
    width: 100%;
    height: 100%;
    position: relative;

    .container {
        border: 1px solid #ececec;
        background: $white;
    }
}


/* Customized */
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}