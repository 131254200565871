.icon-list {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    z-index: 2;
    position: relative;
    @include xy-grid;
    margin: 2em 0 3em;

    .button {
        display: flex;
        margin: 0 0 1em 0;
        @include xy-cell(12, true, 0);

        @include breakpoint(large) {
            @include xy-cell(6); 
        }

        @include breakpoint(xlarge) {
            @include xy-cell(3);
        }
    }

    &.large, &.medium {
        
        .button {
            padding: 1rem 0;
            background: $light-gray;
            color: $black;
            font-size: 1em;
            text-transform: none;
            justify-content: center;
            align-items: center;
            line-height: 1.25;
            max-width: none;
            margin-bottom: 0;
            letter-spacing: 1px;
            margin-top: .5rem;
            cursor: pointer;
            transition: 500ms color;
            border-radius: 25px;

            @include breakpoint(large){
                border-radius: 0;
            }

            &::after{ display: none; }

            &:hover {
                .button-text {
                    text-decoration: underline;
                }
            }

            &:focus {
                background-color: transparent !important;
                color: $water-blue-dark !important;
                text-decoration: underline;
            }

            img {
                max-width: 50px;
                @include breakpoint(xlarge) {
                    max-width: 125px;
                }
            }

            @include breakpoint(large) {
                @include xy-cell(6);
            }

            @include breakpoint(xlarge) {
                @include xy-cell(auto, true, 0);
                padding: 1.5rem .5rem .5rem .5rem;
                flex-direction: column;
                background: transparent;
            }

            .button-text {
                box-sizing: inherit;
                font-weight: $global-weight-bold;
                @include breakpoint(large down) { box-sizing: content-box;}
            }
        }
    }

    .svg { width: 80px; }
    
    .svg.water-and-sewer-payment {
      
        transition: transform 400ms;
    
        .st0{fill:none;}
        .st1{fill:none;stroke:#002D74;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;}
        .st2{fill:none;stroke:#124981;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;}
        .st3{fill:#FFDE75;stroke:#FFDE75;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
        .st4{fill:#C5C7D9;}
    }
    
    .svg.start-or-stop-service {
        transition: transform 400ms;
    
        .a,.b,.e{ fill:none; }
        
        .a{ stroke:#ffde75; }
        
        .a, .e{
            stroke-linecap:round;
            stroke-linejoin:round;
            stroke-width:5px;
        }
        
        .c{ fill:#c5c7d9; }
        
        .d{ fill:#124981; }
        
        .e{ stroke:#124981; }
    }
    
    .svg.report-power-outage {
        transition: transform 400ms;
    
        .a{fill:#c5c7d9;}
        
        .b, .c{
            fill:none;
            stroke-linecap:round;
            stroke-linejoin:round;
            stroke-width:5px;
        }
        
        .b{stroke:$water-blue-medium;}
        
        .c{stroke:#ffde75;}
    }
    
    .svg.construction-in-your-area {
        transition: transform 400ms;
        //width: 44px;
    
        .a{ fill:#c5c7d9; }
        
        .b,.c,.d{ 
            fill:none;
            stroke-linecap:round;
            stroke-linejoin:round;
            stroke-width:5px;
        }
        
        .b{ stroke:#005596; }
        
        .c{ stroke:#124981; }
        
        .d{ stroke:#ffde75; }
    }
    
    .svg.contact-us {
        transition: transform 400ms;
        transition: transform 400ms;
        .a,.c,.d,.e,.f{fill:none;}
        
        .b{fill:#c5c7d9;}
        
        .c,.d{stroke:$water-blue-medium;}
        
        .c,.d,.e,.f{
            stroke-linecap:round;
            stroke-linejoin:round;
        }
        
        .c,.d,.e{stroke-width:5px;}
        
        .d{fill-rule:evenodd;}
        
        .e,.f{stroke:#ffde75;}
        
        .f{stroke-width:4px;}
    }

}

.home .icon-list {
    
    span {
        @include breakpoint(xlarge) {
            font-size: 1.1em;
            margin: 1em 0;
        }
    }

    .button {
        text-align: left;
        justify-content: flex-start;
        background: transparent;
        border: 2px solid $water-blue-dark;

        @include breakpoint(large) {
            border: none;
        }

        @include breakpoint(xlarge) {
            justify-content: center;
            text-align: center;
        }

        img, .svg {
            width: 25px;
            margin-right: 14px;
            position: absolute;
            left: 10px;

            &.construction-in-your-area {
                width: 17px !important;
                left: 14px;
            }

            @include breakpoint(xlarge) {
                position: static;
                width: 75px;
                margin-right: 0;

                &.report-power-outage {
                    width: 87px;
                    top: 7px;
                    position: relative;
                }
                
                &.contact-us {
                    width: 87px;
                    top: 7px;
                    position: relative;
                }
                &.construction-in-your-area {
                    width: 43px !important;
                }
                
                &.start-or-stop-service {
                    width: 70px !important;
                }
            }
        }

        .button-text {
            position: relative;
            left: 45px;
            font-weight: $global-weight-normal;;
            font-size: .9em;
            color: darken($water-blue-dark, 15%);
            font-weight: $global-weight-bold;

            @include breakpoint(large) {
                font-weight: $global-weight-normal;;
            }

            @include breakpoint(xlarge) {
                position: static;
                font-size: 1em;
            }
        }

        &:hover, &:focus {
            color: darken($water-blue, 20%);
    
            .svg {
                &.water-and-sewer-payment, &.svg.start-or-stop-service, &.svg.report-power-outage, &.svg.construction-in-your-area, &.contact-us {
                    transform: scale(1.2)
                }
            }
        }
    }   
}

.simple-button-list {
    .button {
        margin-right: 10px;
    }
}

.cta-list {
    padding: 1.3em 0;
    z-index: 3;
   
    @include breakpoint(xlarge) {
        background: white;
        position: relative;
        padding: 0;
    }

   .icon-list {
       margin: 0;
    }
}
