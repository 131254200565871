.factoids {
    margin: 0;
    background-color: $white;

    .factoid {
        color: #fff;
        text-align: center;
        padding: 2rem 3rem;
        overflow: hidden;
        position: relative;
        min-height: 300px;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            z-index: 1;
        }

        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            z-index: 2;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: left top;
            background-size: 50% auto;
        }

        .content {
            position: relative;
            z-index: 3;
            top: 50%;
            transform: translateY(-50%);
            @include mui-animation(fade(0, 100));
            animation-duration: 2s;
        }

        &.default {
            background: $water-blue;

            .intro,
            .unit {
                color: $power-yellow;
            }

            .context {
                color: $white;
            }

        }

        &.secondary {
            background: $dark-tangerine;

            .intro,
            .unit {
                color: darken($water-blue, 6%);
            }

            .context {
                color: darken($water-blue, 6%);
            }

        }

        &.green {
           //border: 1px solid lighten($dark-tangerine, 30%);
            background-color: lighten($dark-tangerine, 45%);
    
            .value, .value-large, .value-small {
                color: $maximum-green;
            }

            .intro,
            .unit {
                color: darken($maximum-green, 20%);
            }
        }

        &.blue {
            //border: 1px solid lighten($dark-tangerine, 30%);
             background-color: lighten($water-blue, 45%);
     
             .value, .value-large, .value-small {
                 color: $water-blue-dark;
             }
 
             .intro,
             .unit {
                 color: darken($water-blue, 20%);
             }
         }

         &.yellow {
            //border: 1px solid lighten($dark-tangerine, 30%);
             background-color: lighten($mellow-yellow, 15%);
     
             .value, .value-large, .value-small {
                 color: darken($dark-tangerine, 15%);
             }
 
             .intro,
             .unit {
                color: darken($dark-tangerine, 5%);
             }
         }

        &.formal {
            //border: 1px solid $maximum-green;
            background-color: lighten($maximum-green, 45%);
    
            .value, .value-large, .value-small {
                color: darken($dark-tangerine, 45%);
            }

            .intro,
            .unit {
                color: darken($maximum-green, 20%);
            }
        }

        .media {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 auto 0.75rem;

            img {
                width: 100%;
            }
        }

        .intro {
            font-family: $body-font-family;
            font-weight: $global-weight-bold;
            letter-spacing: 0.05em;
            color: $power-yellow;
            font-size: 1.2rem;
            line-height: 1.2;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            sup {
                font-size: 0.5em;
                top: -.5em;
            }
        }

        .value {
            font-size: 3.25rem;
            font-family: $header-font-family;
            font-weight: $global-weight-bold;
            line-height: 1;
            letter-spacing: 0.05em;
            margin-bottom: 0.25rem;

            sup {
                font-size: 0.5em;
                top: -.75em;
            }
        }

        .value-large {
            font-size: 5rem;
            font-family: $header-font-family;
            font-weight: $global-weight-bold;
            line-height: 1;
            letter-spacing: 0.05em;
            margin-bottom: 0.25rem;

            sup {
                font-size: 0.5em;
                top: -.75em;
            }
        }

        .value-small {
            font-size: 2.25rem;
            font-family: $header-font-family;
            font-weight: $global-weight-bold;
            line-height: 1;
            letter-spacing: 0.05em;
            margin-bottom: 0.25rem;

            sup {
                font-size: 0.5em;
                top: -.75em;
            }
        }

        .unit {
            font-family: $body-font-family;
            font-weight: $global-weight-bold;
            letter-spacing: 0.05em;
            color: $power-yellow;
            font-size: 1.2rem;
            line-height: 1.2;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin-bottom: 0.25rem;
        }

        .context {
            font-size: 1rem;
            color: $black;
        }

    }

}