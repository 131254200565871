 // This is for the alert bar.

.alert {
    padding: 1em 0;
    position: absolute;
    width: 100%;
    z-index: 1000 !important;

    .close-button {
        color: $black;
    }

    &.alert-primary, &.alert-secondary, &.alert-warning, &.alert-alert {
        padding: 2rem 0;
        p { font-size: 1.1rem; }
        opacity: .95;
    }

    &.alert-primary {
        background-color: lighten($power-yellow, 45%);
    }

    &.alert-secondary {
        background-color: $light-gray;
    }

    &.alert-warning {
        background-color: lighten($warning-color, 50%);
    }

    &.alert-alert {
        background: lighten($alert-color, 40%);
    }
}
