.map {
    .image-container {
        overflow: hidden;
        position: relative;
    
        .image {
            transition: transform 300ms ease-in;
        }

        .enlarge {
            height: 60px;
            width: 60px;
            line-height: 60px;
            background-color: rgba(0,0,0,.5);
            font-size: 1.6rem;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -30px;
            margin-top: -30px;
            color: #fff;
            transition: transform .5s ease-in-out,opacity .5s;
            opacity: 0;
            transform: scale(.9);
            transform-origin: center;
            text-align: center;
            z-index: 2;

            .material-icons-round {
                font-size: 28px !important;
                top: 6px;
            }
        }
    }
    

    h2, h3, h4, h5, p {
        color: $black;
    }

    a {
        &:hover {
            text-decoration: none;

            h2, h3, h4, h5 {
                text-decoration: underline;
            }

            .enlarge {
                opacity: 1;
                transform: scale(1);
            }

            .image {
                transform: scale(1.1);
                
                h2, h3, h4, h5 {
                    text-decoration: underline;
                }
            }
        }

        &:focus {
            outline: none;
            h2, h3, h4, h5 {
                outline: inherit;
            }

            text-decoration: none;
        }
    }

    &.widget {
        background: $light-gray;
        padding: 1em;

        p {
            font-size: .9em;
        }

        .name {
            padding: 1em 1em 0 0;
            font-size: 1em;
        }
    }
}