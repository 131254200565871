.page-intro {
    background: $light-gray;
    padding: 2em 0;

    @include breakpoint(large){
        margin-top: 0;
        padding: 3em 0;
    }

    &.no-header {
       .grid-container {
            &::after {
                display: none;
            }
        }
    }

    .body {
        display: none;
    }

    .grid-container {
        .text-container {
            width: 100%;
            @include breakpoint(large){ width: 50%; }
        }

        &::after {
            height: 2px;
            background-color: $water-blue;
            width: 25%;
            content: '';
            display: block;
            max-width: 150px;
            margin: 1em 0 2em 1.2rem;

            @include breakpoint(large){
                margin: 2em 0 1em;
            }
        } 
    }

    &.has-image {
        background: $water-blue-dark;
        color: white;
        padding: 0;
        position: relative;
        overflow: hidden;
        margin-bottom: 0;
        max-height: 350px;
        
        /* @include breakpoint(xlarge) {
            min-height: 266px;
        } */

        .text-container {
            position: relative;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: end;
            width: 100%;
            height: auto; 
            font-size: 0.8rem;
            z-index: 3;
            background: transparent;
            @include flex-order(2);
            background: $water-blue-dark;
            color: white;

            @include breakpoint(xlarge) {
                height: 100%;
                bottom: 0; 
                font-size: 1.2rem;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                background: transparent;
                text-align: left;
                color: white;
                padding: .5em 0;

                &.dark {
                    color: $water-blue-dark;
                }

                &.text-right {
                    .text-body {
                        float: right;
                        text-align: right;
                     }
                 }
             }

            .text-body {
                padding: 1.5em 1em;
                
                @include breakpoint(xlarge){
                    padding: 1em 0;
                    max-width: 40%;
                }

                h1 { 
                    font-size: 1.6rem;
                    margin-bottom: .5rem;
                    font-weight: $global-weight-bold;
                    
                    @include breakpoint(xlarge){
                        font-size: 2.7rem;
                        margin-bottom: 1rem;
                        line-height: 1;
                    }

                    @include breakpoint(xxxlarge){
                        font-size: 4.2rem;
                    }
                }

                p {
                    font-size: 1.2em;

                    @include breakpoint(xlarge){
                        font-size: .9em;
                    }
                }
            }
        }

        .body {
            width: auto;
            overflow: hidden;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            order: 1;
            position: relative;
            flex-direction: column;

            img {
                min-width: 100%;
                width: 100%;
                left: auto;
                margin-top: 0
            }
        }
        
        .grid-container {
           position: relative;
           z-index: 1;

           &:after {
               display: none;
           }
        }

        #edit-search, #edit-search--2, #edit-search--3 {
            height: 4rem;
         }
      
         #edit-submit-search--2,  #edit-submit-search--3 {
             max-height: 4em;
         }
      
         #edit-actions, #edit-actions--2, #edit-actions--3 {
            float: left;
            width: 20%;
      
            .button {
              font-size: inherit;
              padding: 1.2em 1rem;
              border: 2px solid transparent;
              background-color: white;
              color: #002D74;
              border-radius: 0 0 0 0
            }
         }
      
    }
    
    .wrap {
        @include xy-grid-container;
       
    }

    .inner  {
        @include xy-grid;
    }

    .column-main {
        @include xy-cell(12);
        margin-bottom: 3rem;
    }

    &.text-center {
        text-align: left;
               
        @include breakpoint(large){
            text-align: center;
        }
    }
}

.layout-single-column {

    .wrap {
        margin: 0 auto;
        clear: both;

        @include breakpoint(large){
            max-width: 80em; //80em
        }
    }    

    &.xsmall { .wrap { max-width: $grid-container-xsmall; } }
    &.small { .wrap { max-width: $grid-container-small; } }
    &.medium { .wrap { max-width: $grid-container-medium; } }
    &.xlarge { .wrap { max-width: $grid-container-xlarge; } }

    &.large {
        .wrap{
            max-width: $grid-container-large; //70em
            
            @include breakpoint(xlarge){
                padding-right: 1.25rem;
                padding-left: 1.25rem;
            }
        }
    }  

    .img-full {
        .image {
            max-height: 650px;
            overflow: hidden;

            img{
                position: relative;

                @include breakpoint(large){
                    //top: -125px;
                }
            }
        }
    }
}

.layout-two-column {
    
    .wrap {
        margin: 0 auto;
        max-width: 60em; //960px
        clear: both;
        padding: 1.3em;
    }

    &.large {
        .wrap{
            max-width: 1120px; //70em

            @include breakpoint(xlarge){
                max-width: 1280px; //80em
            }

            @include breakpoint(xxlarge){
                max-width: 1360px; //85em
                padding: 0 3rem;
            }
            
            @include breakpoint(xxxlarge){
                max-width: 1440px; //90em
            }
        }
    } 

    &.max {
        .wrap{
            max-width: none; //70em

            @include breakpoint(xxxlarge){
                max-width: 110em;
                padding: 0 3rem;
            }
        }
    }  
    
    .inner  {
        @include xy-grid;
    }

    .column-main {
        @include xy-cell(12, true, 0);
        @include breakpoint(large){
            margin-bottom: 3rem;
        }
    }

    .column-support {
        @include xy-cell(12, true, 0);
        //margin-bottom: 3rem;

        .media-card {
            padding: 1em 0;

            .card-content {
                margin-left: 0;
            }
        }
    }

    @include breakpoint(xlarge) {

        .column-main {
            @include xy-cell(9, true, 0);
            padding-right: 2em;
        }

        .column-support {
            @include xy-cell(3, true, 0);
        }

    }

    &.sidebar-left {
        @include breakpoint(xlarge) {

            .column-main {
                @include flex-order(2);
                @include xy-cell(9);
                padding: 0 0 0 2rem;
            }

            .column-support {
                @include flex-order(1);
            }

        }
    }

    &.sidebar-right {
        @include breakpoint(xlarge) {

            .column-main {
                @include xy-cell(7, auto, 0);
            }

            .column-support {
                @include xy-cell(4);
                @include grid-col-off(1);
                padding: 1.5em 1em;
            }

            &.tight {
                .column-main {
                    @include xy-cell(auto);
                    margin: 0 auto;
                    max-width: $grid-container-small
                }
    
                .column-support {
                    @include xy-cell(4);
                    @include grid-col-off(0);
                    padding: 1.5em 1em;
                }
            }

        }
    }
}

.layout-three-column {

    .wrap {
        @include xy-grid-container;
    }

    .inner  {
        @include xy-grid;
    }

    .column-nav {
        @include xy-cell(12);
        margin-bottom: 3rem;
    }

    .column-main {
        @include xy-cell(12);
        margin-bottom: 3rem;
}

    .column-support {
        @include xy-cell(12);
        margin-bottom: 3rem;
    }


    @include breakpoint(xlarge) {

        .column-nav {
            @include xy-cell(3);
        }

        .column-main {
            @include xy-cell(9);
        }

        .column-support {
            @include xy-cell(9);
            @include grid-col-off(3);
        }

    }

    @include breakpoint(xxxlarge) {

        .column-nav {
            @include xy-cell(3);
        }

        .column-main {
            @include xy-cell(auto);
        }

        .column-support {
            @include xy-cell(3);
            @include grid-col-off(0);
        }

    }

}
