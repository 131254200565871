.list-linked {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
    max-width: 800px;
    position: relative;

    &.small {
        li {
            margin-bottom: 5px;
            
            a {
                p {
                    font-size: .9rem;
                    left: 22px;
                }

                &:before {
                    width: 22px;
                    height: 15px;
                    top: 2px;
                    font-size: 16px;
                }
            }
        }
    }

    &.medium {
        li {
            margin-bottom: 0;
            a {
                p {
                    font-size: 1rem;
                    left: 26px;
                }

                &:before {
                    width: 28px;
                    height: 28px;
                    top: 6px;
                    font-size: 22px;
                }
            }
        }
    }

    &.large {
        li {
            margin-bottom: 0;

            a {
                p {
                    font-size: 1.05em;
                    left: 30px;
                }

                &:before {
                    width: 30px;
                    height: 30px;
                    top: 9px;
                    font-size: 26px;
                }
            }
        }
    }

    li {
        
        a {
            font-size: 1rem;
            color: $water-blue-dark;
            text-decoration: none;
            position: relative;

            &:hover {
                text-decoration: underline;
            }

            p {
                color: $water-blue-dark;
                position: relative;
                top: -25px;
                padding: 0 1em 0 0;
                margin-bottom: -25px !important;
            }

            &:before {
                position: relative;
                left: 0;
                top: 0;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-family: "Material Icons Round";
                width: 0;
                content: "chevron_right";
            }

            &.play-video:before {
                content: 'play_arrow';
            }

            &.conservation-icon:before {
                content: '';
                background: url(../img/icon_conservation.svg) no-repeat;
                background-size: 90%;
            }

            &.recycled-water-icon:before {
                content: '';
                background: url(../img/icon_recycled-water.svg) no-repeat;
                background-size: 90%;
            }

            &.ground-water-icon:before {
                content: '';
                background: url(../img/icon_ground-water.svg) no-repeat;
                background-size: 90%;
            }

            &.non-potable-water-icon:before {
                content: '';
                background: url(../img/icon_non-potable-water.svg) no-repeat;
                background-size: 90%;
            }

            &.innovation-icon:before {
                content: '';
                background: url(../img/icon_innovation.svg) no-repeat;
                background-size: 90%;
            }

            &[target$="_blank"]:before { content: "launch"; }
            
            &[href$=".pdf"]:before { content: "picture_as_pdf"; }

            &[href$=".doc"]:before, &[href$=".docx"]:before { content: "article"; }

            &[href$=".xls"]:before, &[href$=".xlsx"]:before, &[href$=".csv"]:before { content: "grid_on"; }

            &[href$=".ppt"]:before, &[href$=".pptx"]:before { content: "image"; }

            //&[href$="(0)"]:before, [href$=".html"]:before { }

            &[href^="#"]:before{ content: "keyboard_arrow_down"; }
        }
    }
}

// Override default list link behavior
.footnote {
    ul {
        margin-bottom: 0;
    }
    
    .list-linked {
        margin-left: .5rem;
    }
}