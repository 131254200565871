.page-header {
    z-index: 999 !important;
    background: white;
    width: 100%;
    text-align: left;
    font-size: 1.3rem;
    font-family: $body-font-family;
    font-size: 1rem;
    padding: 0;
    transition: box-shadow 700ms;
    border-bottom: $medium-gray;

    @include breakpoint(large) {
       box-shadow: none;
    }

    .mobile-menu-logo {
        width: 178px;
        position: relative;
        z-index: 1;
        top: 3px;
        left: 10px;
        padding: 0;

       // small logo icon
        .small {
            display: none;
        }
    }

    .primary-menu .tabs-panel .menu {
        margin: 0;
    }

    .site-logo {
        @include xy-cell(12);
        margin: 14px 0 0 0;
    
        a { padding: 0; }

        @include breakpoint(xlarge) {
            @include xy-cell(3, true, 0);
        }

        @include breakpoint(xxlarge) {
            @include xy-cell(4, true, 0);
        }

        &.content {
            display: none;
    
            @include breakpoint(large) {
                display: block;
                padding: .2em 0;
            }
    
            img {
                width: 200px;
                
                @include breakpoint(xlarge) {
                    width: 220px; 
                } 
    
                @include breakpoint(xxlarge) {
                    width: 240px; 
                }
            }
        }
    }

    .navigation {
        a {
            color: $black;
        }

        @include breakpoint(xlarge) {
            @include xy-cell(auto, true, 0);
        } 

    }

    // Default lists
    ul {
        list-style: none;
        margin: 0;
        min-height: 100%;
        display: block;
        padding: 0;

        @include breakpoint(large) {
            display: flex;
            padding: 0;
            height: auto;
            width: auto;
            background: none;
        }
    }

    // Default menu href's
    a {
        display: block;
        padding: .55rem 1.2rem;
        text-decoration: none;
        transition: color 500ms ease;

        &:focus, &:hover {
            text-decoration: none;
        }

        @include breakpoint(large) {
            border: 0; /* Remove borders from off-canvas styling */
        }

        @include breakpoint(xlarge) {
            padding: .75rem 1.2rem;
        }
    }

    .tags {
        a {
            display: inline !important; 
            color: $water-blue-dark;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .primary-menu {
       

        @include breakpoint(xlarge){ 
            width: auto;
        }

        .is-drilldown {
            max-width: none !important;

            .drilldown a {
                background: transparent;
            }
        }

        .menu {
            float: right;

            @include breakpoint(xlarge) {
                margin: 22px 0 0;
            }

            @include breakpoint(xxlarge) {
                margin: 35px 0 0;
            }
    
            li {
                margin-left: .1rem;
                margin-right: .1rem;
    
                @include breakpoint(large) {
                    margin-left: .8rem;
                    margin-right: 1.4em;
                }
    
                &.search-button {
                    @include breakpoint(large) {
                        margin-right: 0;
                    }
                }
            }
    
            a {
                line-height: 1.2;
                padding: .6rem 0;
    
                @include breakpoint(large) {
                    font-size: 1rem;
                }
            }

            .primary-nav-item {
                padding-bottom: .75em;
                position: relative;
                text-align: center;
                font-size: .85em !important;

                @include breakpoint(medium){
                    font-size: 1em !important;
                }

                &:after {
                    content: '';
                    height: 4px;
                    position: absolute;
                    top: 94%;
                    width: 100%;
                    background-color: $water-blue-dark;
                    display: block;
                    transform-origin: center;
                    transform: scaleX(0);
                    transition: transform 200ms ease-in-out;
                }

                &.hover {
                    &:after { 
                        transform: scaleX(1);
                        background-color: $water-blue;
                    
                    }
                }
    
               &:hover {
                    color: $water-blue-dark;
                    background: transparent;
                    
                    &:after {
                        transform: scale(1);
                    }
                }
            
            }
    
        }

        // Site Search Button
        .search-button {
            display: inline-block;
            background: transparent;
            text-align: center;

            @include breakpoint(large){ 
                width: auto;
                margin: 0;
                border: none;
                font-size: 1rem;
            }

            .material-icons-round {
                font-size: 26px !important;
            }

            a {
                color: $water-blue-dark;
                transition: color 500ms;
                padding-top: 0;

                &:hover {
                    color: $water-blue;
                }

            }
        }
    
    }

    .primary-header {
        display: none;
        background-color: $white;
        transition: all 700ms ease-in-out;
    
        .grid-container {
            padding-top: 0;
            //padding-bottom: 0.4rem;
            position: relative;
            @include clearfix;
    
            // I'm not sure what Foundation is doing here, but it's causing some spacing issues so lets hide it.
            &:after {
                display: none;
            }
        }
    
        @include breakpoint(large){
            display: flex;
            margin: 0;
        }
    }

    .passive-navigation {
        background-color: transparent;
        display: none;

        @include breakpoint(large) {
            position: relative;
            display: block;
            padding: 0;
            margin: 0;
            height: 28px;
            top: 44%;
            left: 123%;
            width: 51%;
        }

        @include breakpoint(xlarge) {
            top: 13px;
            width: auto;
            left: 0;
        }

        .button {
            text-transform: none !important;
            letter-spacing: 0;
            padding: .6em 1em !important;
            margin: 0 5px 0;

            &:hover {
                color: white !important;
                text-decoration: none !important;
            }

            &:focus {
                text-decoration: underline  !important;
                color: $water-blue-dark !important;
                background: transparent !important;
                border-color: transparent !important;
            }
        }

        .menu {
            @include breakpoint(large){
                right: 10px;
                position: absolute;
                border-bottom: none;  
            }

            li {
                float: right;
            }
    
            a { 
                padding: .55rem .8rem;
                font-size: .75em;
                position: relative;

                &:hover {
                    color: $water-blue-dark;
                    text-decoration: underline;
                }
    
                span {
                    &.active-alert-notifications {
                        background-color: #BF2620;
                        border-radius: 50%;
                        color: white;
                        font-size: .8em;
                        padding: 4px 0;
                        position: absolute;
                        top: 0;
                        right: -8px;
                        width: 18px;
                        height: 18px;
                        text-align: center;
                    }
                }
            }
        } 
    
    }

    // States
    &.is-stuck {
        box-shadow: 0px 10px 13px 0px rgba(0,0,0,0.25);

        .mobile-menu {
            .menu {
                margin: 0;
                left: 0;
                top: 1px;
                right: auto;
                width: 100% !important;
            }

            .mobile-menu-logo {
                width: 35px;
                margin: 0 auto;
                left: auto;
                right: auto;

                .small { display: block; }
                .large {display: none }
            }

            .global-mobile-search-trigger {
                right: 0;
            }
        }

        .primary-header {
            margin: 0;

            .primary-menu .menu .primary-nav-item {
                font-size: 1rem;
            }
        }
        
    }

}

.mobile-menu {
    padding: .1rem 0 .8rem;
    z-index: 2;

    @include breakpoint(large){
        display: none;
    }

    ul {
        position: absolute;
        right: 7px;
        top: 10px;
        width: auto;

        > li {
            display: inline-block;

            a {
                padding: .6rem .5rem .4rem .5rem;
            }
        }
    }

    .page-header-toggle, .global-mobile-search-trigger {
        font-size: .9rem;
        color: $water-blue-dark;
    }

    .page-header-toggle {
        .material-icons-round {
            top: 5px !important;
        }
    }

    /* Button styling */
    .global-mobile-search-trigger {
        display: inline-block;
        padding: .8em;
        line-height: 1em;
        position: absolute;
        top: -1px;
        z-index: 1;
        text-align: right;
        right: 74px;
        
        @include breakpoint(large) {
            display: none;
        }
    }

}

// Mobile Menu
.menu-explore {
    background: $water-blue-dark;
    color: #fff;
    overflow: scroll !important;
    height: 100%;
    z-index: 1000 !important;
    display: block;

    @include breakpoint(large){
        display: none;
    }

    &.position-right {
        width: 100vw;
    }
  
    .scroll {
        min-height: 100vh;
        overflow-y: auto;
        padding: 2rem 2rem 150px 2em;
        position: relative;

        .white-logo {
            max-width: 250px;
            margin-bottom: 2rem;
        }

        .close-button {
            color: white;
        }

        .menu {
            li { 
                border-bottom: none;

                .is-submenu-item {
                    font-size: .85em !important; 
                    padding: .5em 0;
                    margin: 0;

                    &:last-child {
                        margin-bottom: 1em;
                    }

                    a { padding: 0 }

                    &.is-active {
                        padding-top: 0;
                        font-weight: bold !important;
                    }
                }
            }
        }
    
    }

    .primary-links {
        margin-bottom: 2rem;

        a {
            color: $white;
            font-size: 1.1em;
            line-height: 1.2;
        }

        .submenu-toggle {
            width: 20px;
            height: 20px;
            top: 15px;
            border-radius: 50px;
            background: rgba(255,255,255,.5);

            &:after {
                border-color: $water-blue transparent;
            }
        }
    }

    .service-links{
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
  
      > ul > li {
          a {
              background-color: transparent;
              border-bottom: none;
              color: #fff;
             //font-size: .9em;
              line-height: 1.3;
        }
     }
     .submenu-toggle {
        width: 20px;
        height: 20px;
        top: 12px;
        color: white;
        background: transparent;

        &:after {
            border-color: white transparent;
        }
    }

    }
}