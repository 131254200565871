.menu .is-active>a {
	background: none;
}


/* Set iframe to take full width */
iframe {
    width: 100% !important;
    margin-top: 20px;

    @include breakpoint(large) {
        width: auto;
        margin-top: inherit;
    }
}

.bottom-img {
    background-image: url(../img/rg/join_now.png);
    background-position: bottom right;
    background-size: 100%;
    background-repeat: no-repeat;
    content: "";
    width: 730px;
    height: 345px;
    position: absolute;
    bottom: -13px;
    right: 0;
    opacity: 0.5;
}

.supp_sidebar {
    padding-top: 40px;
    
    p {
        font-size: .9rem;
    }

    .contact_logo {
        margin-top: 35px;

        a:after {
         display: none;
        }
    }
}