.split-content-w-media-group {

  .section-intro {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 5rem;
  }

}

.split-content-w-media {

  .content {

    h1, h2, h3, p {
        color: #fff;
    }

    .button, .btn-text {
      margin-top: .75rem;
    }

  }

}

.split-content-w-media {

  .content {
    text-align: center;
    background: black;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 300px auto;
    padding: 3rem 2rem;
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 0;
      background: $water-blue-dark;
     /*  background: rgb(28,190,202);
      background: linear-gradient(0deg, rgba(28,190,202,1) 0%, rgba(0,114,206,1) 100%); */
      content: "";
    }

    @include breakpoint(xlarge) {
      padding: 4rem 3rem;
    }

    @include breakpoint(xxxlarge) {
      padding: 5rem 4rem;
    }

    @include breakpoint(xxxxlarge) {
      padding: 6rem 5rem;
    }

    .inner {
      max-width: 650px;
      margin: 0 auto;
      position: relative;
      z-index: 3;

      @include mui-animation(fade);
      animation-duration: 2s;
    }

    *:last-child {
      margin-bottom: 0;
    }

  }

  .media {
    background-color: $dark-gray;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 300px;

   @include breakpoint(large) {
        min-height: 400px;
    }

  }

}

.split-content-w-media.left {
  @include breakpoint(xlarge) {

    .content {
      @include flex-order(1);
    }

    .media {
      @include flex-order(2);
    }
  }
}

.split-content-w-media.right {
  @include breakpoint(xlarge) {
    .content {
      @include flex-order(2);
    }

    .media {
      @include flex-order(1);
    }
  }
}
