.image-grid-section {
    margin: 3rem 0;
    background: $medium-gray;
   
    @include breakpoint(xlarge) {
        margin: 5rem 0;
        padding: 2em 4em;
    }

    .section-intro {
        margin-bottom: 3rem;
    }

    .count {
        font-weight: 600;
        position: relative;
        text-align: center;
        margin-top: 1rem;

        .counter {
            font-size: 1.4rem;

            .current {
                font-weight: 700;
            }

        }

        @include breakpoint(xlarge) {
            top: -3rem;
            margin-top: 0;
            text-align: left;

            .counter {
                padding-left: 12rem;
            }
        }

    }

}


.image-grid {

    @include breakpoint(xlarge) {
        padding: 0;
    }

    &.w-video {
        .owl-item {
            
            .item {
                height: 100%;

                @include breakpoint(xlarge) {
                    &.landscape {
                        width:100% !important;
                        position: relative;
                        display: block;

                        iframe {
                            display: block;
                            width: 100%;
                            height: 200px;
                            border: none;

                            @include breakpoint(large){
                                max-width: 100%;
                                height: 384px;
                            }
                         
                        }
                    }
                }
                
               
            }
        }

        .owl-carousel {

            .owl-stage {
                margin-bottom: 2rem;
                /* @include breakpoint(large){
                    padding-bottom: 4em;
                } */
            }

            .owl-nav {

                button.owl-next,
                button.owl-prev {
                    font-size: 1rem;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    border-radius: 50%;
                    display: inline-block;
                    margin: 0 1rem 0 0;

                    &.disabled {
                        opacity: .4;
                        cursor: disabled;
                    }
                }
            }

            .owl-item {
                overflow: visible;
                height: 100%;

                .item {
                    > a {
                        display: block;
                        border-bottom: none;
                        overflow: hidden;
                        position: relative;

                        &:focus,
                        &:hover {
                            &:after {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background: rgba($black, .5);
                                content: "";
                            }

                            .enlarge {
                                opacity: 1;
                                transform: scale(1);
                            }

                            .image-caption {
                                opacity: 1;
                            }

                            img {
                                transform: scale(1.1);
                            }
                        }
                    }

                    .media {

                       img {
                            transition: all 600ms ease-in-out;
                        }
                    }

                    .card-content {
                        @include breakpoint(large){
                            padding: 1.5em;
                        }
                    }

                    .collapse {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;

                        .enlarge {
                            height: 60px;
                            width: 60px;
                            line-height: 60px;
                            background-color: rgba($black, .5);
                            font-size: 1.6rem;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            margin-left: -40px;
                            margin-top: -30px;
                            color: white;
                            transition: transform 0.5s ease-in-out, opacity 0.5s;
                            opacity: 0;
                            transform: scale(.9);
                            transform-origin: center;
                            text-align: center;
                            z-index: 2;
                        }

                        .image-caption {
                            text-align: center;
                            position: absolute;
                            bottom: 20px;
                            left: 20px;
                            right: 20px;
                            padding: 1rem;
                            transition: opacity .75s;
                            opacity: 0;


                            p {
                                font-size: 1rem;
                                line-height: 1.4;
                                color: $white;
                                margin-bottom: 0;
                            }

                        }
                    }

                    @include breakpoint(xlarge) {
                        &.landscape {
                            width: 500px;
                        }

                        &.portrait {
                            width: 375px;
                        }
                    }

                }
            }
        }
    }
}