.profile-content {
    margin: 2em 0; 

    @include breakpoint(xlarge) { 
        margin: 3.5em 0;
    }

    &.with-background {
        .inner-content {
            position: relative;
            background-color: $light-gray;
            padding: 2rem;
        }

        @include breakpoint(xlarge) {
            padding: 2rem;

            .inner-content {
                padding: 3.5rem 5rem;
            }
        }
    }
}