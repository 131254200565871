ol.list-stepped {

    list-style: none;
    margin-left: 0;
    counter-reset: section;

    ol {
        list-style: none;
        counter-reset: section;
    }

    li {
        margin-bottom: 10px;

        &:before {
            background-color: $water-blue;
            color: white;
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            text-align: center;
            line-height: 30px;
            vertical-align: middle;
            padding: 10px;
            margin-bottom: 10px;
            margin-right: 10px;
            font-weight: $global-weight-bold;
            text-align: center;
            counter-increment: section;
            /* Increments only this instance of the section counter */
            content: counters(section, ".") " ";
        }

    }

}
