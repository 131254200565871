.menu-shield {
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0% !important;
    right: 0% !important;
    margin: 0 auto;
    visibility: hidden;
    transition: opacity 400ms;
    opacity: 0;
    top: 0;

    &.is-open {
        visibility: visible;
        opacity: 1;
    }
}

.secondary-nav-box {
    z-index: 1002;
    top: 104px;
    position: relative;
    color: $black;
    display: block;
    
    h3 {
        font-weight: $global-weight-bold;
    }

    .grid-container {
        padding-bottom: 1em;
        background: #ffffff;
        box-shadow: 2px 2px 3px rgba(0,0,0,.3);
        padding: 0 !important;

        .grid-x {
            .cell {
                &:first-child {
                    background: $water-blue-dark;
                    .category {
                        color: white;
                        font-size: 1.1em;
                    }
                }
            }
        }

        .copy {
            padding: 2.2rem;
            color: white;

            a.button {
               /*  -webkit-appearance: button;
                -moz-appearance: button;
                appearance: button; */
                text-decoration: none;
                display: inline-block;
                padding: 0.7rem 1rem;
            }

            .button.light {
                color: white;
            }
        }
    }

    .tabs-content {
        padding: 0;
        background-color: #f1f1f1;
        height:100%;

        .menu {
            float: left !important;

            a:hover {
                text-decoration: underline;
            }
        }

        .media-card-list {
            padding: 1.5em;
        }

        .media-card {
            padding: 1em .5em;
        }
    }

    .icon-bar {
        position: absolute;
        top: 32px;
        left: 34%;
    }

    .category {
        color: $water-blue-dark;
        font-size: .7em;
    }

    .tabs-panel {
        padding: 2em 0 0 1.5em;
        width: 100%;
        height: 100%;
        z-index: 1;

        h5 {
            font-size: 1.1em;
        }

        .menu {
            padding: 0 2em 2em 0;
            
            li {
                margin-left: 0 !important;
                
                &:first-child {
                    font-weight: $global-weight-bold;
                }

                a {
                    padding: .5em 0;
                    color: $water-blue-dark;
                }
            }
        }
    }

   .menu-list {
        list-style: none;
        margin: 1.5em 0 2rem 1.5rem;
        line-height: 1.5;
        float: none;
        font-weight: $global-weight-bold;
        padding: 0;
        display: flex;
        flex-direction: column;

        li {
            margin: 0 !important;
        }

        a {
            display: block;
            width: 100%;
            @include fluid-type(12px);
            padding: .8em .4em !important;
            border-bottom: 1px $medium-gray solid;
            color: $water-blue-dark !important;

            &:hover {
                text-decoration: underline;
            }
        
            .material-icons-round {
                float: right;
                font-size: 1.2em !important;
                top: 0;
            }
        }
    }

    .header-bar {
        .close-modal {
            position: absolute;
            right: 0;
            top: 0;
        }
    }   
}