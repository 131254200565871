a.media-card {
    h4, h5 {color: $water-blue-dark}

    &:hover {
        text-decoration: none;
        
        h4, h5 {
            text-decoration: underline;
        }
    }
}

.media-card {
    text-decoration: none;
    @include xy-grid;
    transition: color 1s;
    text-align: left; 
    max-width: none;
    padding: 1em 0;
    margin: 0;

    &:last-of-type {
        margin-bottom: .5rem;
        border-bottom: none;
    }

   h5, h6, p, div {
        color: $black;
    }

    h5 a, h4 a {
        color: $water-blue-dark;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    h5, h6 {
        font-weight: $global-weight-normal;;
    }

    a {
        color: $water-blue-dark;
    }

    p {
        font-size: .9em;
        margin-bottom: 1em;
    }

    .link {
        color: $water-blue-dark;
    }

    .image, .date {
        margin-bottom: 1rem;
        max-height: 250px;

        @include breakpoint(large) {
            @include xy-cell(6);
            min-width: inherit;
            margin-bottom: 0;
            max-height: 95px;
        }
        
        @include breakpoint(xlarge) {
            @include xy-cell(3, true, 0);
        }
    
        img { width: 100%; }
    }

    .date {
        background: $water-blue-medium;
        color: $white;
        @include xy-cell(2, true, 0);
        min-width: 100px;
        max-width: 170px;
        padding: 0;
    
        .month, .day {
            line-height: 1;
            position: relative;
            display: block;
                width: 96%;
                margin: 0 auto;
                text-align: center;

            /* @include breakpoint(large) {
                display: block;
                width: 96%;
                margin: 0 auto;
                text-align: center;
            } */
        }

        .month {
            font-family: $header-font-family;
            color:  white;
            font-size: 1.3em;
            top: 23px;
            text-decoration: uppercase;
            /* @include breakpoint(large) {
                font-size: 1.3em;
                top: 23px;
            } */
            
        }

        .day {
            font-weight: $global-weight-bold;
            font-size: 2.1em;
            top: 26px;
            /* @include breakpoint(large) {
                font-size: 2.1em;
                top: 26px;
            } */
        }

     /*    @include breakpoint(large) {
            @include xy-cell(2, true, 0);
            min-width: 100px;
            max-width: 170px;
            padding: 0;
        } */
    }
    
    // default
    .card-content {
        position: relative;
        padding: .5em 1em;
        @include xy-cell(12, true, 0);

        @include breakpoint(large) {
            @include xy-cell(auto);
        }

        h5 {
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
        }

       /*  p:first-of-type {
            margin-bottom: 0;
        } */

        .material-icons {
            font-size: 19px;
        }
    }
    
    &.show-for-xlarge {
        display: none;
        @include breakpoint(xlarge) {
            display: block;
        }
    }

    .specs {
        border: none;
        margin-bottom: 2rem;
    }

    &.featured {
        .image, .date {
            max-height: 140px;
        }

        &:first-of-type {
            max-width: none;
            width: 100%;
            margin-bottom: 1rem;
    
            @include breakpoint(xlarge) {
                h5 {
                    font-size: 1.5em
                }
            }
    
            .image {
                order: 1;
                @include xy-cell(12, true, 0);
                @include breakpoint(medium){ max-height: 400px; }
                overflow: hidden;
            }
    
            .card-content {
                @include xy-cell(12, true, 0);
                order: 2;
                padding-top: 1em;
            }
    
        }  
    }
    
}

.media-card-list {
    @include xy-grid;

    .views-row { 
        width: 100%;
        flex-flow: row wrap;
        display: flex;
    }

    &.projects {
        .media-card {
            .image {
                @include breakpoint(large){
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }

    // Block theme specific
    &.blocks {
        .media-card {
            @include breakpoint(large){
                background: $light-gray;
                border: 1px solid $medium-gray;
                padding: 1.4rem;
                margin-top: 0;
                margin-bottom: 1.5rem;

                &:first-child {
                    padding-top: 1.4em;
                }
            }

            .card-content {
                .tags {
                    a {
                        @include breakpoint(medium down) {
                            display: block;
                            margin-bottom: .5rem;
                        }
                    }
                    .text-divider {
                        @include breakpoint(medium down) {
                            display: none;
                        }
                    }
                }
            }
        }
    } 

    // Document Specific
    &.documents {

        &.white {

            .media-card {
                .icon {
                    .material-icons-round {
                        color: white;
                    }
                }
                .card-content p {
                    color: white;
                }
            }

        }
        
        .media-card {
            background: transparent;
            position: relative;
            
            &:hover {
                .icon {
                    .material-icons-round {
                        color: $water-blue;
                    }
                }
                
                .card-content {
                    text-decoration: underline;
                }
            }
            
            @include breakpoint(large){ 
                margin-bottom: 1.2em;
                @include xy-cell(6);
                padding: 0;
                border-bottom: none;
            }

            .icon {
            
                .material-icons-round {
                    font-size: 2em !important;
                    display: inline-block;
                    transition: font-size 250ms ease-in-out, color 400ms;
                    color: $water-blue-dark;
                    top: -2px;
                    width: 45px;
                
                    @include breakpoint(large){ 
                        font-size: 2.5em;
                        display: inline-block;
                    }
                }
            }

            .card-content {
                p {
                    font-size:  1rem;
                    font-weight: $global-weight-bold;

                    @include breakpoint(large){ 
                        font-size: 1em;
                        
                        margin: 10px 20px;
                    }
                }
            }
        }
    }

    &.widget {
        .media-card {
            
            .image, .date {
                margin-left: 0;
                margin-right: 1.25em;
                width: 100px;
                height: 100px;
                max-width: none;
                max-height: none;
            }
        }
    }

    // Default
    .media-card {
        max-width: none;
        @include xy-cell(12, true, 0);

        @include breakpoint(large) {
            padding-right: 1em;
            padding-top: 2rem;
            padding-bottom: .5rem;

            // Removing this for the sake of the Upcoming Meetings page with uncertain consequences
            /* &:first-child {
                padding-top: 0;
            } */
        }

        .image {
            @include xy-cell(12, true, 0);
            max-height: 300px;
            overflow: hidden;

            @include breakpoint(large) {
                @include xy-cell(4);
                max-height: 250px;
            }
           
         }

        .card-content {
            @include xy-cell(auto, true, 0);
        }
    }

}

.media-card-grid {
    @include xy-grid;
    align-items: flex-start;

    .views-element-container {
        width: 100%;
    }

    .media-card {
        margin-bottom: 2.38em;
        background-color: white; 
        align-content: baseline;
        @include xy-cell(12, true, 0);
        box-shadow: 2px 3px 4px rgba(0,0,0,.1);

        @include breakpoint(large) {
            @include xy-cell(4);
        }

        /* @include breakpoint(xxlarge) {
            @include xy-cell(3);
        } */
        
       .image {
            order: 1;
            @include xy-cell(12);
            @include breakpoint(medium){ max-height: 300px; }
            overflow: hidden;
        }

        .card-content {
            @include xy-cell(12, true, 0);
            order: 2;
        }
    }

    &.projects {
        
        margin-bottom: 2em;

        .media-card {
            
            @include xy-cell(auto);
            background: white !important;

            @include breakpoint(large){
                max-width: 25%;
                float: left;
            }
    
            .card-content {
                padding: 1.5em;
            }
        }
    }

    &.events {
        .media-card {
            @include xy-cell(auto);
            background: white !important;
        }
    }

    &.documents {

        &.white {

            .media-card {
                .icon {
                    .material-icons-round {
                        color: white;
                    }
                }
                .card-content p {
                    color: white;
                }
            }

        }

        .media-card {
            background: transparent;
            position: relative;
            border-bottom: none;
            
            &:hover {
                .icon {
                    .material-icons-round {
                        color: $maximum-green;
                        font-size: 4em !important;
                    }
                }

                .card-content {
                    text-decoration: underline;
                }
            }
            
            @include breakpoint(large){ 
                @include xy-cell(auto);
                text-align: center;
                padding: 1.5em;
            }

            .icon {
                
                @include breakpoint(large){ 
                    position: absolute;
                    top: 0;
                    left: 50%;
                    margin-left: -50px;
                    width: 100px;
                }
                
                .material-icons-round {
                    font-size: 3em !important;
                    display: inline-block;
                    transition: font-size 250ms ease-in-out, color 400ms;

                    @include breakpoint(large){ 
                        font-size: 3.5em;
                        display: block;
                    }
                }
            }

            .card-content {
                p {
                    @include breakpoint(large){ 
                        font-size: .9em;
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}

.filters {
    .media-card-grid {
        margin-top: 1em;
    }
}

.all-events {
    .media-card-grid {
        .media-card {
            padding: 0;
            border: $light-gray 1px solid;

            .date {
                width: 100%;
                max-width: none;
                margin: 0 0 15px 0;
                text-align: left;
                height: auto;
                padding: 6px 12px;
                top: 0;

                .month, .day {
                    font-size: 1em;
                    display: inline-block;
                    width: auto;
                    margin: 0;
                    text-align: left;
                    top: 0;
                }
            }

            h5 {
                font-size: 1em;
            }

            .card-content {
                padding: .5em 1.2em;
            }
        }
    }
}

.news-archive {
    .media-card-grid {
        .media-card {
            border: #f1f1f1 1px solid;
            
            h5 {
                font-size: 1em;
            }

            .card-content {
                padding: .5em 1.2em;
            }
        }
    }
}

.news-list-block {
    border-bottom: none;
    position: relative;
    display: block;
    margin: 0 0 1rem;


    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: block;
            border-bottom: dashed 1px #cecfd0;

            a {
                text-decoration: none;
                padding: 13px 20px 14px;
                display: block;
            }
        }
    }

    .thumbnail {
        margin: 0;
        border-width: 1px;
    }
}



.upcoming-events {
    .media-card {
        @include breakpoint(large) {
            padding: 1em;
            //min-height: 265px;
        }
    }
}


