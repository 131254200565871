html, body {
    overflow-x: hidden;
}

#base {
    background: darken($white, 3%);
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;
    position: relative
}

section {
    margin-bottom: 3.2em;

    &:last-of-type {
        margin: 0;
    }
}

a {
    text-decoration: none;
    transition: color 400ms;
    color: $water-blue-dark;
    
    &:hover, &:focus {
       text-decoration: underline;
    }

    &.underline {
        text-decoration: underline;
    }
}


@media (hover: none) {
    a:hover { color: inherit; }
}

ol > li > ol {
    list-style: lower-alpha;
}

ul > li > ul {
    list-style: circle;
}

p {
    font-size: 17px;
    margin-bottom: 1rem;

    &.small {
        font-size: 14px;
    }
}

.lead {
    margin-bottom: 1.5rem;
}

hr {
    max-width: none;
}

dl dd {
    margin-bottom: .8rem;
}

.anchor {
    ::before { 
        display: block; 
        content: " "; 
        margin-top: -285px; 
        height: 285px; 
        visibility: hidden; 
        pointer-events: none;
      }
}

blockquote {
    font-size: 2rem;
    padding: 4rem 2rem .4rem 1rem;
    border: none;
    position: relative;
    //color: lighten($black, 10%);
    color: black;

    cite {
        position: absolute;
        bottom: 1rem;
        color: lighten($black, 15%);
    }

    &:before {
        position: absolute;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: "\e244";
        font-family: 'Material Icons Round';
        font-style: normal;
        font-size: 2em;
        color: rgba($black, .15);
    }

    &:before {
        top: 7px;
        left: -11px;
    }

  /*   &:after {
        bottom: 0;
        left: -7px;
    } */

}

.citation {
    text-indent: -20px;
    padding-left: 40px;
    font-size: 1.1rem;
    color: #000;
}

h1, h2, h3, h4, h5, h6 {
    > a {
        text-decoration: none;
    }
}

/* h3 {
    font-weight: $global-weight-normal;
} */

h5, h6 {
    font-family: $body-font-family;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}


.reveal {
    padding: 2rem;
    transition: opacity 1s;
    opacity: 0;

    h5, p {
        margin: 0;
    }

    p { 
        margin-bottom: .4rem;
    }
}

.is-reveal-open {
    .reveal {
        opacity: 1;
    }
}

/* section {
    padding: 2rem 0;

    @include breakpoint(large){
        
        padding: 3rem 0;
    }
}
 */
 
 .landing-intro {
     .container {
        text-align: center;
        padding: 2em;
        margin-bottom: 2em;
        background: #f1f1f8;

        @include breakpoint(large){
            padding: 3em;
            max-width: 100%;
            margin-bottom: 3em;
        }

        p {
            font-size: 120%;
        }
     }
 }
 
.subheader {
    font-size: $lead-font-size * 1.2;
    color: #4c545a;
    margin-bottom: 1.4em;
}

.align-center {
    //padding: 1rem 0 1.5rem;

    .field-media--field-media-oembed-video iframe:first-of-type {
        width: 100%;
        height: auto;
        min-height: 526px;
    }
}

.align-left {
    float: left;
    width: 100%;
    margin-bottom: 1em;

    @include breakpoint(large){
        padding-right: 1.2rem; 
        max-width: 50%;
        margin-bottom: 1em;
    }

    @include breakpoint(xlarge){
        max-width: 33%;
    }

    .field-media--field-media-oembed-video iframe:first-of-type {
        width: 100%;
        height: auto;
        min-height: 350px;
        float: left;
    }
}

.align-right {
    float: right;
    width: 100%;
    margin-bottom: 1em;

    @include breakpoint(large){
        padding-left: 1.2rem;
        max-width: 50%;
        margin-bottom: 1em;
       
    }

    @include breakpoint(xlarge){
        max-width: 33%;
    }

    .field-media--field-media-oembed-video iframe:first-of-type {
        width: 100%;
        height: auto;
        min-height: 350px;
        
    }
}

#default {
    .mt-0 { margin-top: 0; }
    .mt-10 { margin-top: 10px; }
    .mt-25 { margin-top: 25px; }
    .mt-50 { margin-top: 50px; }
    .mt-100 { margin-top: 100px; }
    .ml-0 { margin-left: 0; }
    .mr-0 { margin-right: 0; }
    .mr-10 { margin-right: 10px; }
    .mr-25 { margin-right: 25px; }
    .ml-10 { margin-left: 25px; }
    .ml-25 { margin-left: 25px; }

    .mb-0 { margin-bottom: 0; }
    .mb-10 { margin-bottom: 10px; }
    .mb-25 { margin-bottom: 25px; }
    .mb-50 { margin-bottom: 50px; }
    .mb-100 { margin-bottom: 100px; }

    .pb-0 { padding-bottom: 0; }
    .pb-25 { padding-bottom: 25px; }
    .pb-50 { padding-bottom: 50px; }
    .p-0 { padding: 0; }
    .p-10 { padding: 10px; }
    .p-25 { padding: 25px; }
    .p-50 { padding: 50px; }

    .pl-25 {
        padding-left: 10px;

        &.desktop-only {
            @include breakpoint(large){
                padding-left: 25px; 
            }
        }
    }

    .material-icons, .material-icons-round {
        position: relative;
        top: 2px;
        font-size: 18px;  /* Override icon size */
    }
}

.cell {
    padding: 1em 0;

    p:last-child { margin-bottom: 0; }
    p:first-child { margin-top: 0; }

    @include breakpoint(large){
        padding: 0;
    }
}

.tabs-title > a {
    &:hover {
        background-color: $light-gray;
    }

    /* &:focus, &[aria-selected='true'] {
        box-sizing: content-box !important;
    } */
}

.menu {
    width: auto !important;
    position: relative;
    right: auto;
    left: auto;

    &.bulleted {
        list-style: disc !important;
    }

    &.horizontal {
        flex-direction: column;
        @include breakpoint(large){
            flex-direction: row;
        }

        li {
            border-bottom: 1px solid rgba(255,255,255,0.2);
            @include breakpoint(large){
                border: none;
                border-left: 1px solid $water-blue;

                &:first-child {
                    border-left:none;
                }
             }

            &:first-child {
                a {
                    @include breakpoint(large){
                        padding-left: 0;
                    }
                }
            }
        }
    }

    a {
        padding-left: 0;
        font-weight: $global-weight-normal;
        
        &:hover {
            text-decoration: underline;
        }

        @include breakpoint(large){
            padding: 0 3rem;
        }
    }

    li {
        border-bottom: 1px solid rgba(255,255,255,0.2);
        @include breakpoint(large){
            border: none;
        }
    }

}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: none;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 1rem;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Material Icons Round";
    content: "keyboard_arrow_down";
}


.accordion-menu .is-accordion-submenu-parent[aria-expanded='true']>a::after {
    transform-origin: default;
    top: 40px !important;
    right: -2px !important;
}

.reset-spacing {
    margin: 0 !important;
    padding: 0 !important;
}

  .footnote {
      margin-top: 15px;
      color: $dark-gray;

      &.overview {
        color: black; 
        border-top: 1px solid #ccc; 
        padding-top: 1.5em;
    }

      &.ribbon {
        margin-bottom: 25px;
      }
  }

  .text-divider {
      display: inline-block;
      position: relative;
      height: 6px;
      width: 6px;
      margin: 0 1em;
      top: -2px;
      background-color: $power-yellow;
  }

  .widget .tags {
        border-left: 1px solid $power-yellow;
        padding-left: 1em;

        li {
            display: block;
            &:after { display: none }
        }

  }

  .tags { 
      font-size: .9em;
      margin-bottom: 0 !important;
      margin-left: 0;
      border-left: 1px solid $power-yellow;
      padding-left: 1em;

      @include breakpoint(large){
          border-left: none;
          padding-left: 0;
      }
      
      p {
          font-size: 1em;
          margin-bottom: 1em;
        }

      .material-icons-round {
          top: 5px !important;
      }

      .button {
          margin: 0;
          padding-top: 0 !important;
          padding-bottom: .2em !important;
      }

      .item, li {
          display: block;
          position: relative;
          padding-right: 3em;

          @include breakpoint(large){
            display: inline;  
            width: auto;
          }

          span {
              font-weight: $global-weight-bold;
          }

          &:last-child {
              &:after {
                  display: none;
              }
          }

          &:after {
            content: '';
            position: relative;
            height: 6px;
            width: 6px;
            top: -2px;
            left: 23px;
            background-color: $power-yellow;
            display: none;

            @include breakpoint(large){
                display: inline-block;
            }
          }
      }

      .status {
            background-color: $maximum-green;
            color: white;
            padding: 1em 2em;
            position: absolute;
            top: -20px;
            right: 0;
            font-weight: bold;
            display: none;
      }
    }

  .meta { margin-bottom: 0.4rem; }

  a:hover {
      .category {
          text-decoration: underline;
          color: $water-blue-dark;
      }
  }

  .category {
    text-transform: uppercase;
    letter-spacing: 0.025rem;
    line-height: 1;
    color: $water-blue-medium;
    display: inline-block;
    vertical-align: middle;
}

 .news-releases, .upcoming-events, .programs-resources, .video-resources {
    @include pad-section;

    h2 {
        margin-bottom: 1em;
    }
    
    @include breakpoint(large){
        //margin-bottom: 3em;
    }

     .menu {
         a {
            padding-top: .3em;
            padding-bottom: .3em;
            padding-left: 0;
            color: $water-blue-dark;
         }
    }
}

.programs-resources, .helpful-videos, .news-events {
    h3 {
        position: relative;
        display: inline-block;
        padding: .5em 0;
        color: darken($water-blue, 25%);
        
        &:after {
            height: 2px;
            background-color: $water-blue;
            width: 25%;
            margin: .5em 0;
            content: '';
            display: block;
        }
    }
}

.news-events {
    padding: 3em 0;
}

.helpful-videos {
   
    padding: 1em 0 5em !important;

    @include breakpoint(large){
        @include pad-section;
    }

    .owl-stage-outer {
        top: 63px;

        @include breakpoint(large){
            top: 0;
        }
    }
}

button:hover{
    svg {
        .body {
            fill: $water-blue;
        }

        .lines {
            fill: transparent;
        }

        .arrow {
            fill: white;
        }
    }
}

svg, button.disabled:hover svg {
    .body, .lines, .arrow {
        transition: fill 700ms;
    }

    .body {
        fill: transparent;
         z-index: -1; 
    }
    .lines {
        fill: rgba($water-blue-dark,1);
    }

    .arrow {
        fill: #002d74;
    }
}

.ribbon {
    border-top: 1px solid $medium-gray;
    border-bottom: 1px solid $medium-gray;
    padding: .5em .8em;
}

.close-button{
    z-index: 1000;
    &__small{ width: 20px; }
    &__medium { width: 30px; }
    &__large { width: 45px; }
}

.show-for-xlarge-up {
    display: none;

    @include breakpoint(xlarge){
        display: block;
    }
}

.event-body-content, .news-body-content {
    .footnote { 
        margin-bottom: 25px;

        a {
            color: $water-blue-dark;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.show-for-sr {
    color: black;
    background: white;
}

.row {
    max-width: none !important;
}