.welcome-container {
    
    width: 100%;
    position: relative;
    z-index: 3;
    text-align: center;
    padding-right: 0.59524rem;
    padding-left: 0.59524rem;
    @include pad-section;
   
    .grid-container {
        padding: 0 !important;
    }

    h1 {
        font-weight: $global-weight-bold;
        position: relative;
        display: inline-block;
        padding: .5em;
        color: $water-blue;
        
        &::after {
            height: 2px;
            background-color: $water-blue;
            width: 25%;
            margin: .5em auto 0;
            content: '';
            display: block;
        }
    
    }
}