.cal_start td {
    width: 14%;
    text-align: right;
    vertical-align: top;
    font-size: 18px;
    line-height: 25px;
    font-weight: normal;
    padding: 3px 8px;
    height: 105px;
    border: 1px solid #f0f0f0;
    border-top: 0;

    .content {
        font-size: .8rem;
        line-height: 1.2;
    }
}