.steps {
    padding: 0 2rem;
    //background-color: $light-gray;

  .section-intro {
    margin-bottom: 3rem;
    text-align: center;
  }

    @include breakpoint(xlarge) {
        padding: 1rem 0;
    }

    &.w-image {
        .step {
            border: 1px solid $maximum-green;
            padding: 1rem;
        }
    }

}

.step {
    text-align: center;
    position: relative;
    @include mui-animation(fade(0, 100));
    animation-duration: 2s;

    .step-number {
       
        background-color: $water-blue;
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        vertical-align: middle;
        padding: 10px;
        margin: 0 auto;

        span {
            font-weight: $global-weight-bold;
            text-align: center;
            z-index: 10000;
            color: white;
            position: absolute;
            width: 50px;
            top: 29px;
            left: 50%;
            margin-left: -25px;

            @include breakpoint(large){
                top: 10px;
            }
        }

       
    }

    h3 {
        color: $black;
    }

    .step-content {
     
      padding: 1rem 1.75rem;

      .step-desc {

        p {
        font-size: 1rem;
        
        }

      }

      .step-convert {
        margin-bottom: 0;
      }

    }

    .step-media {

        img {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

}

.step-by-step-grid {
    height: 100%;

    .step {
        margin-top: 1rem;
        margin-bottom: 0;
    }

}
