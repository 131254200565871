tbody {
    background: rgba($white, 0.7);
}

tbody tr:nth-child(even) {
    background: rgba($black, .05);
}

thead {
    background: rgba($black, .1);

    th {
        padding: 0.5rem 0.625rem 0.625rem;
    }
}

table.hover {
    thead {
        tr:hover {
            cursor: pointer;
            background: rgba($black, .15); 
        }
    }

    tbody {
        tr:hover {
            cursor: pointer;
            background: rgba($water-blue, .1); 
        }

        tr:nth-child(even):hover {
            cursor: pointer;
            background: rgba($water-blue, .15);
        }
    }
}
