.content-w-media {
    padding: 1rem 0 .5rem 0;
    margin: 2rem 0;

    &:last-child {
        margin-bottom: 0;
    }

    @include breakpoint(xlarge) {
        padding: 3rem 0 2rem 0;
        margin: 4rem 0;
    }
    
    .wrap {
        @include xy-grid-container;
    }

    .inner {
        @include xy-grid; 
    }

    .content {
        @include xy-cell(12, true, 0);
        justify-content: center;
        margin-bottom: .5rem;
        padding: 1.5rem 1rem 0 1rem;
        @include flex-order(2);
        position: relative;
        
        h2 {
            color: $water-blue;
            font-weight: $global-weight-bold;
        }

        @include breakpoint(xlarge) {
            @include xy-cell(6);
            padding: 2rem;
            margin-bottom: 0;
        }

        li {
            margin: 0 0 0.45em 1.5em;
        }
    }

    .media {
        @include xy-cell(12, true, 0);
       
        @include breakpoint(xlarge) {
            @include xy-cell(6);
        }

        img {
            width: 100%;
        }

    }

    .content-foot-links {
        width: 100%;
        margin-top: 2rem;

        a:first-of-type{
            margin-right: 40px;
        }
    }

    @include breakpoint(xlarge) {

        &.haltered {
            .media {
                max-height: 675px;
                overflow: hidden;
                @include xy-cell(7);
            }

            .content {
                z-index: 2;
                opacity: 0.8;
                background: white;
                top: 20px;
                @include xy-cell(5);
            }
            
        }

        // This wont do anything until its set up
        &.full-wrap {
            .media, .content {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

}

.content-w-media.left {

    @include breakpoint(xlarge) {

        .content {
            @include flex-order(1);
        }

        .media {
            @include flex-order(2);
        }

        &.haltered {
            .content {
                left: 50px;
                /* margin-left: 0;
                padding-left: 0; */
            }
        }

    }

}

.content-w-media.right {

    @include breakpoint(xlarge) {

        .content {
            @include flex-order(2);
            background: white;
            position: relative;

        }

        .media {
            @include flex-order(1);
        }

        &.haltered {
            .content {
                left: -50px;
               /*  margin-right: 0;
                padding-right: 0; */
            }
        }
    }
}

.content-w-media + .content-w-media {
    border-top: none;
}

.off-canvas-content {
    &.is-open-left{
        @include breakpoint(xlarge){
            .content {
                @include xy-cell(5);
            }
    
            .media {
                @include xy-cell(7);
             }
        }
    }
}
