
.video-gallery-carousel  {

    width: 100vw !important;
    position: relative !important;
    left: 0;

    h5 {
        font-family: "Libre Franklin", sans-serif;
        font-weight: $global-weight-normal;;
        color: $water-blue-medium;
    }

    .tags {
       color: darken($water-blue, 10%);;
        
        .material-icons {
            color: $red-pigment;
        }
    }

    .owl-video-play-icon {
        height: 60px;
        width: 60px;
        margin-left: -30px;
        margin-top: -30px;
        background-size: contain;
    }

    .body {
        padding: 1em 0em;

        @include breakpoint(large) {
            padding: 2em 2em 2.5 1em;
        }
    }

    .owl-video-wrapper {
        min-height: 200px;

        @include breakpoint(large) {
            min-height: 293px;
        }

        .owl-video-tn {
            background-position: 0px -34px;
            background-size: 112%;

            @include breakpoint(large) {
                background-position: 0px -71px;
                background-size: 120%;
            }
        }
    }

    .owl-nav {
        text-align: left !important;
    
        &[class*='owl-']:hover {
            background: transparent;
        }
    
        .owl-prev,  .owl-next {
            width: 38px;
            height: 38px;

            span {
                display: none;
            }

            &:focus {
                outline: none;
            }
        }
    
        .owl-prev {
            background: transparent url("../../assets/img/orbit-previous.svg") no-repeat top left !important;
            margin-right: 7px;

            &.disabled {
                opacity: .5;
            }
        }
    
        .owl-next {
            background: transparent url("../../assets/img/orbit-next.svg") no-repeat top left !important;

            &.disabled {
                opacity: .5;
            }
        }
    }
    
    .owl-dots { display: none; }
}





