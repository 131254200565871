.w-img {
    padding: 6rem 0;
    background-color: #f1f1f1;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bordered {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.w-color {
    padding: 4rem 0;

    &.primary {
        background-color: $water-blue;
        color: #fff;
    }

    &.secondary {
        background-color: lighten( $light-gray, 10% )
    }

    &.no-padding {
        padding: 0;
    }
}

#at-a-glance {
    margin-bottom: 2em;
}

.page-content {
    
    .section-highlight {
        padding: 2rem 1em;
        //margin-bottom: -1rem;

        a::after {
            display: none;
        }
    
        @include breakpoint(large){
            margin-bottom: -3rem;
            padding: 2rem;
        }
      
    }
}

.section-highlight {
    padding: 1rem 0;
    background-color: $light-gray;

    &.white {
        background-color: $white;
        padding: 0 0 1em;
    }

    &.gray { background-color: $light-gray; }
    &.blue { background-color: $water-blue-light }

    &.border-bottom {
        border-bottom: 1px solid $light-gray;
    }
   
    .section-intro {
        margin-bottom: 3rem;
    }
  
    @include breakpoint(large) {
        padding: 2rem;
    }
}

#at-a-glance {
    @include breakpoint(large){
        .cell:nth-child(2){
            border-left: 1px solid #cacaca;
        }
    }
}


