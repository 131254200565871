.site-footer {
    position: relative;
    z-index: 5;
    background: $water-blue-dark;
    font-size: .9rem;
    padding: 0 .5rem;

    @include breakpoint(large){
        padding: 2em 0 0;
    }

    .small-text {
        font-size: $small-font-size;
    }
    
    .footer-container {

        padding: 0;
        color: white;

        .cell {
            margin-bottom: 1.5rem;

            @include breakpoint(large){
                margin-bottom: 1.5rem;
            }
        }

        .white-logo {
            max-width: 242px;
            margin: 20px auto 15px auto;
            display: block;

            @include breakpoint(large){
                margin: 10px auto 20px auto;
                max-width: 270px;
                display: inline-block;
            }
        }

        .cell {
            padding: 0;

            @include breakpoint(large){
                padding: 0 1.5em;
            }
        }

        h4 {
            color: $mellow-yellow;
            font-weight: $global-weight-bold;
            text-transform: uppercase;
            font-size: 1rem;
            display: block;
            @include breakpoint(large){
                font-size: 1.1rem;
            }
    
            &.underline {
    
                display: block;
    
                @include breakpoint(large) {
                    display: inline-block;
                }
                &::after {
                    margin: 1rem 0;
                    padding: 0;
                    display: none;
                    @include breakpoint(large){
                        display: inline-block;
                    }
                }
            }
        }

        .menu {

            width: 100%;
            position: relative;
            right: auto;
            left: 6px;
            font-size: 1.2em;

            @include breakpoint(large){
                left: auto;
            }

            &.horizontal {
                flex-direction: column;
                @include breakpoint(large){
                    flex-direction: row;
                }
    
                li {
                    border-bottom: 1px solid rgba(255,255,255,0.2);
                    @include breakpoint(large){
                        border: none;
                    }
                }
            }
    
            a {
                padding-left: 0;
                font-weight: $global-weight-normal;
                color: white;
            
                &:first-child {
                    @include breakpoint(large){
                        padding-left: 0;
                    }
                }
        
                &:hover {
                    text-decoration: underline;
                }
    
                @include breakpoint(large){
                    padding: 0.5rem 3rem;
                }
            }

            li {
                border-bottom: 1px solid rgba(255,255,255,0.2);
                @include breakpoint(large){
                    border: none;
                }
            }
        }

        a {
            padding-left: 0;
            @include breakpoint(large){
                padding: 0.5rem 3rem;
            }
        }

        .nav-social {
            li {
                @include xy-cell(auto, true, 30);
                border: none;
                max-width: 40px;

                a { 
                    padding: 20px 0;
                    @include breakpoint(large){
                        padding: 0;
                    }
                }
            }
          
        }

        .nav-global {
            margin: 0 auto 0 auto;
            text-align: center;
    
            p, a {
                font-weight: $global-weight-bold;
                color: #b5c4d0;
            }
    
            p {
                margin: 0;
            }
        
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
        
                &.primary-set {
                    margin-bottom: 5px;
                    a {
                        color: white;
                        text-decoration: underline;
                    }
                }
            }
        
            li {
                display: inline-block;
                margin-right: 1rem;
                
                a {
                    padding: 0;
                    text-decoration: none;
        
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }

            a { text-decoration: underline; }
        
        }

        @include breakpoint(large) { padding: 2em 0; }
    }
    
    
}

.nav-social {
    text-align: left;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        
        display: inline-block;
        font-size: 1.4rem;
        margin: 0rem .2rem .25rem .2rem;

        &:first-child{
            margin-left: 0;
        }
    }

    a {
        display: inline;
        line-height: 1;
        color: white;
        
    }

    .menu {
        li {margin: 0 }
        a {font-size: .8rem }
    } 

}

.toggle-trigger {
    padding: 14px 0 8px 6px;
    background-color: transparent;
    width: 100%;
    font-weight: $global-weight-normal;
    border-bottom: 1px solid #3977a7;
    cursor: pointer;
    text-align: left;

    i {
        margin-top: 0;
        margin-right: 10px;
        float: right;
    }

    @include breakpoint(large) {
        padding: 0;
        background-color: transparent;
        border-bottom: none;
        font-weight: $global-weight-bold;
        margin: 0;
        cursor: default;
    }
}
