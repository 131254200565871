.vertical-tabs {

   .tabs {
       text-align: left;
       border: none;
       background: transparent;
       
       &.tabs-centered {
        
            .tabs-title {
                display: inline-block;
                float: none;
        
                > a {
                    font-size: 1rem;
                    padding: .75rem;
                }
            }
        }

        // tab interaction
        .tabs-title {
            width: 80%;

            h4 {
                margin-bottom: 0;
                font-size: 1rem;
            }

            &.is-active {
                background: none;

                > a {
                    &:hover {
                        background-color: darken($water-blue, 40%) !important;
                        color: white;
                    }
                }
            }
            
            > a {
                border-radius: 25px;
                padding: 1rem 1.2rem;
                text-transform: none;
                text-decoration: none;
                background: $water-blue;
                margin: 0 .3rem .5rem;
                font-weight: normal;
                color: $white;
                position: relative;
    
                &:hover {
                    color: $black;
                    background: rgba(43, 43, 43, 0.4);
                }
    
                &:focus {
                    background-color: $water-blue;
                    color: #fff;
    
                    &:hover {
                        background-color: $water-blue;
                    }
                }

                &:after {
                    position: absolute;
                    right: 0;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f0da";
                    bottom: 5px;
                    transition: transform 800ms ease-in-out, opacity 400ms, color 400ms;
                    color: black;
                    font-size: 3.5em;
                    opacity: 0;
                    color: $water-blue;
                    
                }
    
                &[aria-selected='true'] {
                    background-color: darken($water-blue, 40%);
                    &:after {
                        transform: translateX(10px);
                        opacity: 1;
                        color: darken($water-blue, 40%);
                    }
                    
                }
            }
        }
    
    }


    // content in the panel
    .tabs-content {
        border: none;
        background: transparent;
        *zoom: 1;
        display: block;
        padding: 1rem 1rem 0 1rem;
        color: $black;

        &::before, &::after {
            content: "";
            display: table; 
        }

        &::after {
            clear: both; 
        }

        &> .tabs-panel {
            display: none;
            float: left;
            padding: 0.9375rem 0;

            &.is-active {
                animation: fadeIn 1s;
                opacity: 1;
                display: block;
            }
        }

        .inner {
            @include xy-grid;
        }

        .content {

            @include breakpoint(xlarge) {
                @include xy-cell(auto);
            }

            li {
                margin: 0 0 0.45em 1.5em;
            }

        }

        .media {

            @include breakpoint(xlarge) {
                @include xy-cell(5);
            }

        }

        li {
            margin-bottom: 1rem;
        }

    }
    
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}