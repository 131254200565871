.timeline {

    .tabs-content {
        border-top: 1px solid $medium-gray;
    }

    .item-info {
        padding: 1.2em !important;
        background: $light-gray;

        @include breakpoint(large){
            padding: 1.5em !important;
        }
    }

    .timeline-reel {
        position: relative;
        padding: 2rem 0;

        &:after {
            content: '';
            width: 5px;
            background-color: $black;
            background: linear-gradient(180deg, rgba($black,1) 0%, rgba($light-gray,0) 100%);
            height: 100%;
            position: absolute;
            top: 0;
            left: 78px;
        }

        .tabs-title {
            float: none;

            a {
                font-size: .9rem;
                padding: .5em 1.5em;

                @include breakpoint(large){
                    padding: 1.25rem 1.5rem;
                }

                &:hover,  &[aria-selected='true'] {
                    background: $light-gray url(../img/timeline-mark.svg) top left no-repeat;
                    color: darken($sewer-green, 30%);
                    background-position: 73px 15px;
                    background-size: 16px 16px;
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }

    .timeline-item {
        display: flex !important;
        width: 100%;
        background-image: url("../img/timeline-mark.svg");
        background-repeat: no-repeat;
        background-position: 73px 15px;
        background-size: 16px 16px;
        margin-bottom: .2rem;
        z-index: 1;
        position: relative;
        @include xy-grid;
        padding: .7em 0 !important;
        align-items: flex-start;
        
        @include breakpoint(large){
            background-position: 73px 15px;
            margin-bottom: 2rem;
        }

        .date {
            font-size: .9em;
            background: darken($medium-gray, 80%);
            color: $white;
            padding: .5em;
            @include xy-cell(3, true, 28);
            max-width: 50px;
        
            text-align: center;
        }

        .event {
        //left: 40px;
            @include xy-cell(auto);
            line-height: 1.3;
            margin-top: 3px;
        }
    }

    .timeline-content {
        .tabs-content {
            border: none;
            padding: 0;
        }

        .tabs-panel {
            padding: 0;
        }
    }
}